window.vg.exports(

  // Nombre del módulo
  'fileset',

  // Factoria del módulo
  function moduleFactory() {
    var utils = window.vg.utils;
    var polyglot = new window.Polyglot();
    polyglot.extend({
      allowedExtensions: 'extensiones permitidas: %{exts}',
      actions: {
        addInput: 'añadir un nuevo archivo',
        delInput: 'eliminar',
      },
      error: {
        extensionNotAllowed:
        'El tipo de archivo no es válido.',
        maxFilesize:
        'El archivo es demasiado grande.',
        maxFiles:
        'Has incluido el máximo de archivos permitido.',
      },
      input: {
        es: {
          button: 'Seleccionar archivo',
          inner: 'Ningún archivo seleccionado',
        },
        eu: {
          button: 'Hautatu Fitxategia',
          inner: 'Ez da fitxategirik hautatu',
        },
        en: {
          button: 'Select File',
          inner: 'No file selected',
        },
        fr: {
          button: 'Choisir le fichier',
          inner: 'Aucun fichier sélectionné',
        },
      },
    });

    /**
     * Factoria de objetos File (input file + boton eliminar)
     * @param  {Element} parent Fieldset padre
     * @param  {Object} opts    Opciones de configuracion del campo
     * @return {Object}         Objeto File
     */
    function fileFactory(parent) {
      var btnRemove;
      var field;
      var input;
      var msg;
      var uid;

      /**
       * Validar si el archivo cumple con los requisitos
       * @return {String}    false si es válido; un mensaje, en caso de error.
       */
      function errors() {
        if (input.files && input.files.length) {
          if (!parent.isFileExtensionValid(input.files[0])) {
            return polyglot.t('error.extensionNotAllowed');
          }
          if (!parent.isFileSizeValid(input.files[0])) {
            return polyglot.t('error.maxFilesize');
          }
        }
        return false;
      }

      /**
       * Inicializar el objeto que contiene el campo file y el boton eliminar
       * @return {undefined}
       */
      function init() {
        uid = utils.uid();

        field = document.createElement('li');
        field.classList.add('field', 'field--file');
        field.innerHTML = [
          '<label class="field__label visually-hidden" for="input-', uid, '">',
          'Archivo',
          '</label>',
          '<input class="field__input" type="file">',
          '<button type="button" class="field__btn-remove btn">',
          polyglot.t('actions.delInput'),
          '</button>',
          '<p class="field__msg msg msg--error is-empty"></p>',
        ].join('');
        field = parent.appendFile(field);

        input = field.querySelector('input');
        input.setAttribute('id', 'input-' + uid);
        input.setAttribute('name', parent.name() + '[' + parent.length() + ']');
        input.addEventListener('change', function () {
          var error = errors();
          if (error) {
            msg.classList.remove('is-empty');
            msg.innerHTML = error;
          } else {
            msg.classList.add('is-empty');
            msg.innerHTML = '';
          }
        });

        btnRemove = field.querySelector('button');
        btnRemove.addEventListener('click', function () {
          parent.removeFile(field, uid);
        });

        msg = field.querySelector('.field__msg');
      }

      init();

      return {
        isEqualTo: function (fileObjOrUid) {
          var compareTo = fileObjOrUid.uid ? fileObjOrUid.uid : fileObjOrUid;
          return uid === compareTo;
        },
        input: function () {
          return input;
        },
        inputName: function (newName) {
          if (arguments.length === 0) {
            return input.getAttribute('name');
          }
          input.setAttribute('name', newName);
        },
        uid: function () {
          return uid;
        },
      };
    }

    /**
     * Instancia y genera la funcionalidad de fieldset de input files variables.
     * @param  {Element} fileset Elemento DOM del fieldset padre
     * @return {undefined}
     */
    function filesetFactory(fileset) {
      var _public;
      var files;
      var maxFilesize;
      var name;
      var allowedExtensions;
      var form;
      var ulfiles;
      var maxFilesError;

      /**
       * Instancia el boton de añadir campo y añade el click
       * @param  {Element} parentNode fileset
       * @return {Element}            boton
       */
      function createButtonAppendFile(parentNode) {
        var btn = parentNode.appendChild(document.createElement('button'));
        btn.setAttribute('type', 'button');
        btn.innerHTML = polyglot.t('actions.addInput');
        btn.addEventListener('click', function (evt) {
          evt.stopPropagation();
          if (files.length < getMaxFiles()) {
            var newfile = fileFactory(_public);
            files.push(newfile);
            newfile.input().click();
          } else {
            maxFilesError.classList.remove('hidden');
          }
        });
        parentNode.addEventListener('click', function (evt) {
          maxFilesError.classList.add('hidden');
        });
        return btn;
      }

      /**
       * Encuentra el formulario del que cuelga el fieldset
       * @param  {Element} elem fieldset
       * @return {Element}      formulario padre
       */
      function getClosestForm(elem) {
        return elem.closest('form');
      }

      /**
       * Devuelve el numero máximo de archivos configurado a través del atributo
       * de la etiqueta fieldset.
       * @return {Number} Numero máximo de archivos
       */
      function getMaxFiles() {
        return fileset.getAttribute('data-maxfiles')
          ? fileset.getAttribute('data-maxfiles')
          : 10;
      }

      /**
       * Instancia el boton de añadir campo y añade el click
       * @return {undefined}
       */
      function init() {
        allowedExtensions = fileset.getAttribute('data-ext')
          ? fileset.getAttribute('data-ext').split(',')
          : [];
        files = [];
        maxFilesize = fileset.getAttribute('data-maxfilesize')
          ? fileset.getAttribute('data-maxfilesize')
          : 2097152;
        name = fileset.getAttribute('data-name');

        var legend = fileset.querySelector('legend');
        if (legend) {
          legend.classList.add('fileset__legend');
        }

        form = getClosestForm(fileset);

        if (form) {
          form.setAttribute('enctype', 'multipart/form-data');
          form.addEventListener('submit', function () {
            console.log('validar files');
          });
        }

        ulfiles = fileset.querySelector('ul');
        if (!ulfiles) {
          ulfiles = fileset.appendChild(document.createElement('ul'));
        }

        maxFilesError = fileset.appendChild(document.createElement('p'));
        maxFilesError.classList.add('msg', 'msg--error', 'hidden');
        maxFilesError.innerHTML = polyglot.t('error.maxFiles');

        createButtonAppendFile(fileset);

        if (allowedExtensions.length > 0) {
          var msgExtensiones = fileset
            .appendChild(document.createElement('span'));
          msgExtensiones.innerHTML = polyglot.t('allowedExtensions', {
            exts: allowedExtensions.join(', '),
          });
        }
      }

      init();

      _public = {
        appendFile: function (field) {
          return ulfiles.appendChild(field);
        },
        isFileExtensionValid: function (file) {
          if (!allowedExtensions || allowedExtensions.length === 0) {
            return true;
          }
          // var ext = filename.substr((~-filename.lastIndexOf(".") >>> 0) + 2);
          var ext = file.name.substr(
            file.name.lastIndexOf('.') + 1
          ).toLowerCase();

          for (var i = allowedExtensions.length - 1; i >= 0; i--) {
            if (allowedExtensions[i] === ext) {
              return true;
            }
          }
          return false;
        },
        isFileSizeValid: function (file) {
          if (maxFilesize && maxFilesize > 0) {
            return file.size <= maxFilesize;
          }
          return true;
        },
        length: function () {
          return files.length;
        },
        name: function () {
          return name;
        },
        removeFile: function (field, uid) {
          var removed = null;
          for (var i = files.length - 1; i >= 0; i--) {
            if (files[i].isEqualTo(uid)) {
              removed = files.splice(i, 1);
              break;
            }
          }
          if (removed !== null) {
            ulfiles.removeChild(field);
            for (var j = files.length - 1; j >= 0; j--) {
              files[j].inputName(name + '[' + j + ']');
            }
          }
          return removed;
        },
      };

      return _public;
    }

    /**
     * Instancia y genera los input files multiidiomas.
     * @param  {Element} input Elemento DOM del input padre
     * @return {undefined}
     */
    // function inputFactory(input) {
    //   const idioma = document.documentElement.lang || 'es';

    //   /**
    //    * Inicializar el objeto
    //    * @return {undefined}
    //    */
    //   function init() {
    //     if (input.getAttribute('class') == null) {
    //       input.classList.add('class', 'field__file');
    //     } else {
    //       if (!input.classList.contains('field__file')) {
    //         input.classList.add('class', 'field__file');
    //       }
    //     }
    //     if (input.getAttribute('data-text') == null) {
    //       input.setAttribute('data-text', polyglot.t(`input.${idioma}.button`));
    //     }

    //     input.addEventListener('change', function (e) {
    //       if (e.target.value) {
    //         input.setAttribute('data-text', polyglot.t(e.target.value.split('\\').pop()));
    //         input.classList.add('class', 'has-file');
    //       } else {
    //         input.setAttribute('data-text', polyglot.t(`input.${idioma}.button`));
    //         input.classList.remove('class', 'has-file');
    //       }
    //     });
    //   }

    //   init();
    // }

    return {
      init: function () {
        var tabs = document.querySelectorAll('.fileset');
        for (var i = 0, max = tabs.length; i < max; i++) {
          filesetFactory(tabs[i]);
        }
        /* var inputs = document.querySelectorAll('input[type="file"]');
        for (var f = 0, fmax = inputs.length; f < fmax; f++) {
          inputFactory(inputs[f]);
        } */
      },
      validate: function (selector) {

      },
    };
  }

);
