window.vg.exports(

  // Nombre del módulo
  'table',

  // Factoria del módulo
  function moduleFactory() {
    var utils = window.vg.utils;
    /**
       * Factoria de tablas
       * @param  {Element} table
       * @param  {Object} opts    Opciones de configuracion del campo
       * @return {Object}         Objeto File
       */
    function tableFactory(table) {
      const node = table;
      /**
         * Inicializar el objeto que contiene el campo file y el boton eliminar
         * @return {undefined}
         */
      (function init() {
        const parent = node.parentNode;
        const wrapper = document.createElement('div');

        parent.replaceChild(wrapper, node);
        wrapper.appendChild(node);

        const wuid = utils.uid();
        node.id = node.id || wuid;

        wrapper.setAttribute('role', 'region');
        wrapper.setAttribute('tabindex', 0);

        var table = wrapper.querySelector('table');
        if (table !== null) {
          table.setAttribute('role', 'table');
          table.classList.add('rs_content'); // Lectura de tablas para readspeaker
        }
        var caption = wrapper.querySelector('caption');
        if (caption !== null) {
          caption.setAttribute('role', 'caption');
          const cuid = utils.uid();
          caption.id = caption.id || cuid;
          wrapper.setAttribute('aria-labelledby', cuid);
        }
        var allRowGroups = wrapper.querySelectorAll('thead, tbody, tfoot');
        if (allRowGroups !== null) {
          for (var irg = 0; irg < allRowGroups.length; irg++) {
            allRowGroups[irg].setAttribute('role', 'rowgroup');
          }
        }
        var allRows = wrapper.querySelectorAll('tr');
        if (allRows !== null) {
          for (var ir = 0; ir < allRows.length; ir++) {
            allRows[ir].setAttribute('role', 'row');
          }
        }
        var allCells = wrapper.querySelectorAll('td');
        if (allCells !== null) {
          for (var icl = 0; icl < allCells.length; icl++) {
            allCells[icl].setAttribute('role', 'cell');
          }
        }
        var allHeaders = wrapper.querySelectorAll('th');
        if (allHeaders !== null) {
          for (var ih = 0; ih < allHeaders.length; ih++) {
            allHeaders[ih].setAttribute('role', 'columnheader');
          }
        }
        // this accounts for scoped row headers
        var allRowHeaders = wrapper.querySelectorAll('th[scope=row]');
        if (allRowHeaders !== null) {
          for (var orh = 0; orh < allRowHeaders.length; orh++) {
            allRowHeaders[orh].setAttribute('role', 'rowheader');
          }
        }
      })();

      return {

      };
    }

    return {
      init: function () {
        var tables = document.querySelectorAll('table');
        for (var i = 0, max = tables.length; i < max; i++) {
          tableFactory(tables[i]);
        }
      },
    };
  }

);

