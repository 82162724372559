vg.exports(

// Nombre del módulo
'utils',

// Factoria del módulo
function () {
  return {
    /**
     * A fast string hashing function for Node.JS
     * https://github.com/darkskyapp/string-hash
     * @param  {String} str
     * @return {Number}
     */
    hash: function hash(str) {
      var hash = 5381,
          i    = str.length

      while(i)
        hash = (hash * 33) ^ str.charCodeAt(--i)

      /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
       * integers. Since we want the results to be always positive, convert the
       * signed int to an unsigned by doing an unsigned bitshift. */
      return hash >>> 0;
    },
    /**
     * Utilidad para determinar si el navegador es alguna versión de IE concreta
     */
    isIE: function isIE(version, comparison) {
      var cc      = 'IE',
          b       = document.createElement('B'),
          docElem = document.documentElement,
          isIE;

      if(version){
        cc += ' ' + version;
        if(comparison){ cc = comparison + ' ' + cc; }
      }

      b.innerHTML = '<!--[if '+ cc +']><b id="iecctest"></b><![endif]-->';
      docElem.appendChild(b);
      isIE = !!document.getElementById('iecctest');
      docElem.removeChild(b);
      return isIE;
    },
    /**
     * Detectar si el usuario utiliza un dispositivo móvil.
     * @return {Boolean} true si es un movil o tablet. False en caso contrario.
     */
    isMobileDevice: function () {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    },
    /**
     * Generate unique ids of any length.
     * https://github.com/matthewmueller/uid
     * @param  {Number} len
     * @return {String}
     */
    uid: function uid(len) {
      len = len || 7;
      return Math.random().toString(35).substr(2, len);
    },
    /**
     * Metodo que te devuelve el lang del document.
     * Si el lang tiene mas de dos caracteres, te retorna los dos primeros.
     * Esta funcion es para arreglar problemas con los blogs.
     * @returns {String}
     * 
     */
    lang: function lang() {
      let idioma = document.documentElement.lang || 'es';
      if(idioma.length > 2) {
        idioma = idioma.substring(0,2);
      }
      return idioma;
    },
    /**
     * Método que comprueba si estamos utilizando un dispositivo Apple.
     * @returns {boolean}
     */
    isIOS: function isIOs() {
      var userAgent = window.navigator.userAgent.toLowerCase()
      return /iphone|ipad|ipod/.test(userAgent)
    },
    /**
     * Método que comprueba si el navegador se está ejecutando en modo independiente.
     * @returns {boolean}
     */
    isInStandalone: function  isInStandalone () {
      if (this.isIOS()) return ('standalone' in window.navigator) && (window.navigator.standalone)
      else return (window.matchMedia('(display-mode: standalone)').matches)
    }
  }
}
);
