vg.exports(

  // Nombre del módulo
  'share',

  // Factoria del módulo
  function moduleFactory() {
    const idioma = window.vg.utils.lang();

    var polyglot = new window.Polyglot();
    polyglot.extend({
      share: {
        es: {
          success: 'Se ha compartido exitosamente.',
          error: 'Ha ocurrido un error al compartir',
          sharesupport: 'Tu navegador no soporta esta función de compartir.',
          filessupport: 'Tu navegador no soporta esta función de compartir ficheros.',

        },
        eu: {
          success: 'Arrakastaz partekatu da.',
          error: 'Errore bat gertatu da partekatzean',
          sharesupport: 'Zure arakatzaileak ez du onartzen partekatzeko eginbide hau..',
          filessupport: 'Zure arakatzaileak ez du onartzen fitxategiak partekatzeko eginbide hau.',
        },
        en: {
          success: 'It has been shared successfully.',
          error: 'An error occurred while sharing',
          sharesupport: 'Your browser does not support this sharing feature.',
          filessupport: 'Your browser does not support this file sharing feature.',
        },
        fr: {
          success: 'Il a été partagé avec succès.',
          error: 'Une erreur s\'est produite lors du partage',
          sharesupport: 'Votre navigateur ne prend pas en charge cette fonctionnalité de partage.',
          filessupport: 'Votre navigateur ne prend pas en charge cette fonctionnalité de partage de fichiers.',

        },
      },
    });

    return {
      compartir: function compartir() {
        if (navigator.canShare) {
          navigator.share({
            title: document.title,
            url: document.URL,
            text: document.title,
          })
            .then(() => console.log(polyglot.t(`share.${idioma}.success`)))
            .catch((error) =>console.log(polyglot.t(`share.${idioma}.error`), error));
        } else {
          console.log(polyglot.t(`share.${idioma}.sharesupport`));
        }
      },
      compartirFicheros: function compartirFicheros(filesArray) {
        if (navigator.canShare && navigator.canShare({files: filesArray})) {
          navigator.share({
            files: filesArray,
            title: 'Pictures',
            text: 'Our Pictures.',
          })
            .then(() => console.log(polyglot.t(`share.${idioma}.success`)))
            .catch((error) => console.log(polyglot.t(`share.${idioma}.error`), error));
        } else {
          console.log(polyglot.t(`share.${idioma}.filessupport`));
        }
      },
    };
  }
);

