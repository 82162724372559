window.vg.exports(
  // Nombre del módulo
  'eventScroll',

  // Factoria del módulo
  function moduleFactory() {
    /**
     * @param  {object} options Objeto con las opciones posibles
     * @return {undefined}
     */
    function eventScrollFactory(options) {
      var scrollHeight = 0;
      var currentscrollHeight = 0;
      var lockScroll = options.lockScroll;
      var paginaActual = 0;
      var capa = options.capa;
      var callback = options.callback;
      var launched = false;

      /**
      * Actualiza varios datos: aumenta la pagina actual,
      * desbloquea el scroll y marca el ultimo punto de scroll
       * @return {boolean}
      */
      function actualizarDatos() {
        paginaActual++;
        currentscrollHeight = scrollHeight;
        launched = true;
        return true;
      }

      /**
       * Marca que se puede volver a lanzar el callback
       * al hacer scroll
       */
      function funcionFin() {
        launched = false;
      }

      /**
       * Inicializa el evento de scroll
       * @return {undefined}
       */
      (function init() {
        window.addEventListener('scroll', function (evt) {
          evt.preventDefault();
          if (!lockScroll) {
            // var scrollHeight = document.body.scrollHeight;
            scrollHeight = capa.scrollHeight;
            // scroll position
            var scrollPos = Math.floor(window.innerHeight + window.scrollY);
            var isBottom = scrollHeight < scrollPos;
            if (!launched && isBottom && currentscrollHeight < scrollHeight) {
              actualizarDatos();
              callback(evt).then(funcionFin);
            }
          }
        });
      })();

      return {
        bloquearScroll: function (bloquear) {
          lockScroll = bloquear;
        },
        getPaginaActual: function () {
          return paginaActual;
        },
        reset: function () {
          paginaActual = 0;
          currentscrollHeight = 0;
          scrollHeight = 0;
          lockScroll = false;
        },
      };
    }

    return {
      init: function (options) {
        var defaultOptions = {
          idCapa: '',
          lockScroll: false,
          callback: function () {
            return true;
          },
        };
        options = Object.assign({}, defaultOptions, options);

        options.capa = document.getElementById(options.idCapa);
        if (!options.capa) {
          console.warn(
            'No se puede inicializar el eventScroll. ' +
              'Tiene que existir el elemento con el id pasado por parametros'
          );
          return;
        }
        return eventScrollFactory(options);
      },
    };
  }
);
