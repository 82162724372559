window.vg.exports(

  // Nombre del módulo
  'backtotop',

  // Factoria del módulo
  function moduleFactory() {
    var botonBackToUp;
    // Initial state
    // var scrollPos = 0;
    // var scrollActive = true;
    window.requestAnimFrame = (function () {
      return window.requestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        function ( callback ) {
          window.setTimeout(callback, 1000 / 60);
        };
    })();

    /**
      * Metodo para hacer scroll al inicio de la pagina (parte superior)
      * un objeto.
      * @param {Object} speed  Velocidad de la animacion
      * @param {Object} easing Tipo de animacion
    */
    function scrollToTop(speed, easing) {
      // scrollTargetY: the target scrollY property of the window
      // speed: time in pixels per second
      // easing: easing equation to use
      var scrollY = window.scrollY || document.documentElement.scrollTop;
      var scrollTargetY = 0;
      var currentTime = 0;

      speed = speed || 2000;
      easing = easing || 'easeOutSine';

      // min time .1, max time .8 seconds
      var time = Math.max(.1,
        Math.min(Math.abs(scrollY - scrollTargetY) / speed, .8));
      // easing equations from https://github.com/danro/easing-js/blob/master/easing.js
      var easingEquations = {
        easeOutSine: function (pos) {
          return Math.sin(pos * (Math.PI / 2));
        },
        easeInOutSine: function (pos) {
          return (-0.5 * (Math.cos(Math.PI * pos) - 1));
        },
        easeInOutQuint: function (pos) {
          if ((pos /= 0.5) < 1) {
            return 0.5 * Math.pow(pos, 5);
          }
          return 0.5 * (Math.pow((pos - 2), 5) + 2);
        },
      };

      /**
        * Metodo que gestiona la animación de la accion de scroll.
      */
      function tick() {
        currentTime += 1 / 60;

        var p = currentTime / time;
        var t = easingEquations[easing](p);

        if (p < 1) {
          window.requestAnimFrame(tick);
          window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
        } else {
          window.scrollTo(0, scrollTargetY);
        }
      }

      // call it once to get started
      tick();
    }

    // Let's set up a function that shows our scroll-to-top button
    // if we scroll beyond the height of the initial window.
    var scrollFunc = function (event) {
      // Get the current scroll value
      // var y = window.scrollY;
      // If the scroll value is greater than the window height,
      // let's add a class to the scroll-to-top button to show it!
      // if (scrollActive && y > 0 && y < scrollPos) {
      // botonBackToUp.className = 'back-to-top back-to-top--show';
      // } else {
      //  botonBackToUp.className = 'back-to-top back-to-top--hide';
      // }
      // scrollPos = y;
      // scrollActive = true;
      var posicionVertical = window.scrollY || window.pageYOffset;

      if (posicionVertical >= 300) {
        botonBackToUp.className = 'back-to-top back-to-top--show';
      } else {
        botonBackToUp.className = 'back-to-top back-to-top--hide';
      }
    };

    return {
      init: function () {
        botonBackToUp = document.getElementById('id-back-to-up');
        if (botonBackToUp) {
          botonBackToUp.onclick = function (e) {
            e.preventDefault();
            scrollToTop(1500, 'easeInOutQuint');
          };
          window.addEventListener('scroll', scrollFunc);
        }
      },
      // activeScroll: function () {
      // scrollActive = true;
      // },
      // deactivateScroll: function () {
      // scrollActive = false;
      // },
    };
  }
);
