window.vg.exports(

  // Nombre del modulo
  'card',

  // Factoria del modulo
  function () {
    var squarify = window.vg.dom.squarify;
    return {
      init: function () {
        var eventCardMeta = document.querySelectorAll('.card__meta');
        for (var i = eventCardMeta.length - 1; i >= 0; i--) {
          squarify(eventCardMeta[i]);
        }
      },
      reload: function () {
        this.init();
      },
    };
  }

);
