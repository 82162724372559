/* global module */
window.vg = window.vg || {};
Object.assign(window.vg, {
  exports: function vgExports(moduleName, moduleFactory) {
    var theModule = moduleFactory();
    var hasExports = typeof module !== 'undefined' && module.exports;

    if (hasExports) { // Node.js Module
      module.exports = theModule;
    } else { // Assign to common namespaces or simply the global object (window)
      (window.vg || window)[moduleName] = theModule;
    }
  },
});

/**
 *****************************************************************************
 * LEGACY QUE DEBE IR ANTES DE LS JS PROPIOS DE VG
 *****************************************************************************/
/**
 * Limpia y prepara los formularios de búsqueda general para que utilicen el
 * nuevo JS de autocompletado y validacion comunes.
 *
 * @param  {Object} vg Paquete VG
 * @return {undefined}
 */
(function adaptarBuscadoresCabecera(vg) {
  /**
   * Eliminar evento onsubmit antiguo:
   * onsubmit="return validarBusqueda(this);"
   * @param  {Element} form  formulario de busqueda
   * @return {undefined}
   */
  function removeValidacionSubmit(form) {
    if (form.hasAttribute('onsubmit') &&
      form.getAttribute('onsubmit').indexOf('return validarBusqueda(') > -1) {
      form.removeAttribute('onsubmit');
    }
  }

  /**
   * Añadir el atributo para autocompletado con Solr
   * @param {Element} input  Input text de la búsqueda
   */
  function addDataAttr(input) {
    input.removeAttribute('onfocus');
    input.setAttribute('data-autocomplete', 'solr');
    input.removeAttribute('id');
  }

  /**
   * Limpia y prepara un formulario de búsqueda general en base a un ID
   * @param {String} formId  Id del form a adaptar
   */
  function adaptarBuscador(formId) {
    var formBuscador = document.getElementById(formId);
    if (formBuscador && formBuscador !== null) {
      removeValidacionSubmit(formBuscador);
      var inputBuscador = formBuscador.querySelector('[type="text"]');
      if (inputBuscador && inputBuscador !== null) {
        addDataAttr(inputBuscador);
      }
    }
  }

  vg.ready(function () {
    adaptarBuscador('buscadortexto');
    adaptarBuscador('formGoogle');
  });
})(window.vg);
