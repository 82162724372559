window.vg.exports(
  // Nombre del módulo
  'summary',

  // Factoria del módulo
  function moduleFactory() {
    const idioma = vg.utils.lang();
    const polyglot = new window.Polyglot();
    polyglot.extend({
      summary: {
        es: {
          tituloIndice: 'Índice de la página:',
          buttonHiddenText: 'Ocultar',
        },
        eu: {
          tituloIndice: 'Web-orriaren aurkibidea:',
          buttonHiddenText: 'Ezkutatu',
        },
        en: {
          tituloIndice: 'Page index:',
          buttonHiddenText: 'Hide',
        },
        fr: {
          tituloIndice: 'Index des pages :',
          buttonHiddenText: 'Cacher',
        },
        invald: 'El contenido no es valido para crear el resumen.',
      },
    });

    let element;

    /**
     * Función para validar si el contenido cumple las condiciones para crear el summary.
     */
    function validateContent() {
      const minH2Elements = 3;
      const maxLength = 1500;
      const headdings = element.querySelectorAll('h2:not(.news-list h2, .main__grid-box h2), h3');

      if (
        headdings.length >= minH2Elements &&
        element.innerText.length >= maxLength
      ) {
        createSummary(headdings);
      } else {
        console.log(polyglot.t('summary.invalid'));
      }
    }

    /**
     * Función para crear el elemento summary.
     * @param {*} headdings
     */
    function createSummary(headdings) {
      const nav = document.createElement('nav');
      nav.setAttribute('class', 'summary');
      nav.setAttribute('id', 'summaryNav');
      nav.setAttribute('aria-live', 'polite');

      if (vg.utils.isMobileDevice() || window.innerWidth < 768) {
        nav.classList.add('hidden-element');
      }

      const header = document.createElement('header');
      header.setAttribute('class', 'summary-header');
      const headding = document.createElement('h2');
      headding.appendChild(
        document.createTextNode(polyglot.t(`summary.${idioma}.tituloIndice`))
      );
      const button = document.createElement('button');
      button.setAttribute('class', 'summary-header-btn');
      button.appendChild(
        document.createTextNode(
          polyglot.t(`summary.${idioma}.buttonHiddenText`)
        )
      );
      button.addEventListener('click', toogleHandle);
      button.dataset.text = polyglot.t(`summary.${idioma}.tituloIndice`);
      header.appendChild(headding);
      header.appendChild(button);

      nav.appendChild(header);

      /* const button = document.createElement('button');
        nav.appendChild(button); */

      const ul = document.createElement('ul');
      ul.setAttribute('class', 'summary-list');

      let lastFirstLevel;

      const createFirstLevelElement = (current, index) => {
        current.setAttribute('id', `summary_title_${index}`);

        const li = document.createElement('li');
        li.setAttribute('class', 'summary-element');

        const a = document.createElement('a');
        a.setAttribute('href', `#${current.id}`);
        a.appendChild(document.createTextNode(current.innerText));
        a.addEventListener('click', clickHandle);

        li.appendChild(a);
        return li;
      };

      const createSecondLevelElement = ({current, index, lastFirstLevel}) => {
        current.setAttribute('id', `sub_summary_title_${index}`);

        let ul = lastFirstLevel.querySelector('.sub-summary-list');
        if (!ul) {
          ul = document.createElement('ul');
          ul.setAttribute('class', 'sub-summary-list');
          lastFirstLevel.appendChild(ul);
        }

        const li = document.createElement('li');
        li.setAttribute('class', 'sub-summary-element');

        const a = document.createElement('a');
        a.setAttribute('href', `#${current.id}`);
        a.appendChild(document.createTextNode(current.innerText));
        a.addEventListener('click', clickHandle);

        li.appendChild(a);
        ul.appendChild(li);
      };

      headdings.forEach((currentHeadding, index) => {
        if (currentHeadding.tagName === 'H2') {
          lastFirstLevel = createFirstLevelElement(currentHeadding, index);
          ul.appendChild(lastFirstLevel);
        } else if (currentHeadding.tagName === 'H3') {
          createSecondLevelElement({
            current: currentHeadding,
            index: index,
            lastFirstLevel: lastFirstLevel,
          });
        }
      });

      nav.appendChild(ul);

      if (element.dataset.tipoContenido === 'Contenido editorial') {
        element
          .querySelector('p.noticias')
          .insertAdjacentElement('afterend', nav);
      } else {
        element.querySelector('h1').insertAdjacentElement('afterend', nav);
      }

      const buttonToogle = document.createElement('button');
      buttonToogle.setAttribute('class', 'btn-summary-toogle');
      buttonToogle.addEventListener('click', toogleHandle);
      buttonToogle.setAttribute('aria-controls', 'summaryNav');

      nav.insertAdjacentElement('afterend', buttonToogle);

      window.addEventListener('resize', function () {
        document.documentElement.classList.remove('scroll-disabled');
        const summary = document.querySelector('.summary');
        if (vg.utils.isMobileDevice() || window.innerWidth < 768) {
          summary.classList.add('hidden-element');
        } else {
          summary.classList.remove('hidden-element');
        }
      });
    }

    /**
     * Función que maneja el evento click sobre un elemento del summary.
     * @param {*} event
     */
    function clickHandle(event) {
      event.preventDefault();

      if (vg.utils.isMobileDevice() || window.innerWidth < 768) {
        document.documentElement.classList.remove('scroll-disabled');
        const summary = document.querySelector('.summary');
        summary.classList.add('hidden-element');
      }
      document
        .querySelector(event.target.getAttribute('href'))
        .scrollIntoView({behavior: 'smooth'});
    }

    /**
     * Función que maneja la visualización del summary.
     * @param {*} event
     */
    function toogleHandle(event) {
      event.preventDefault();
      const summary = document.querySelector('.summary');
      summary.classList.toggle('hidden-element');

      if (vg.utils.isMobileDevice() || window.innerWidth < 768) {
        document.documentElement.classList.toggle('scroll-disabled');
      } else if (!summary.classList.contains('hidden-element')) {
        summary.scrollIntoView({behavior: 'smooth'});
      }
    }

    return {
      init: function () {
        element = document.querySelector('#wb021contenido[data-summary=true]');
        if (element) validateContent();
      },
    };
  }
);
