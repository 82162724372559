!function(global){

  if (typeof global.Array.prototype.forEach !== 'function') {

    global.Array.prototype.forEach = function(callback, context) {
      for (var i = 0; i < this.length; i++) {
        callback.apply(context, [ this[i], i, this ]);
      }
    };
  }

if (typeof global.NodeList !== "undefined" && global.NodeList.prototype && !global.NodeList.prototype.forEach) {
  global.NodeList.prototype.forEach = global.Array.prototype.forEach;
}

}(window);
