/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
window.vg.exports(

  // Nombre del módulo
  'submenu',

  // Factoria del módulo
  function moduleFactory() {
    var menuMovil;
    var linksMenuMovil;
    var utilsMovil;
    var searchOpenButton;
    var widthWindows;

    /**
     * Funcion para tratar el submenu al cargar
     * la ventana dependiendo del tamaño de pantalla
     * @return {undefined}
     */
    function switchSubmenu(innerWidth) {
      var submenus = document.getElementsByClassName('dropdown-menu submenu');
      var header = document.getElementsByClassName('header')[0];
      var buscarContainer = document.getElementById('header-search');
      if (!searchOpenButton || !buscarContainer) {
        return;
      }

      if (innerWidth > 1199) {
        for (const item of Array.from(submenus)) {
          header.appendChild(item);
        }

        header.appendChild(buscarContainer);
        searchOpenButton.removeEventListener('click', showMobileSearch);
        hideMobileSearch();
      }

      if (innerWidth <= 1199) {
        var menuElements = document.getElementsByClassName('dropdown');
        var index = 0;
        for (const item of Array.from(submenus)) {
          menuElements[index].appendChild(item);
          index++;
        }
        searchOpenButton.addEventListener('click', showMobileSearch);
      }
    }

    /**
     * Funcion para mostar u ocultar el menu de busqueda
     * @return {undefined}
     */
    function showMobileSearch(evt) {
      evt.stopPropagation();
      var expand = searchOpenButton.getAttribute('aria-expanded') === 'false';
      var searchField = document.querySelector('.main-nav__search-form');
      searchField.hidden = false;
      searchField.removeAttribute('style');

      var searcher = document.querySelector('.field--search');
      searcher.hidden = false;

      menuMovil.setAttribute('aria-hidden', expand);
      menuMovil.hidden = !menuMovil.hidden;
      menuMovil.classList.toggle('main-nav--visible');
      // menuMovil.classList.toggle('full-width');

      linksMenuMovil.classList.toggle('hidden');
      utilsMovil.classList.toggle('hidden');
      var smartphoneNavOverlay = document.querySelector('.main-nav-overlay');
      smartphoneNavOverlay.classList.toggle('main-nav-overlay--visible');
    }

    function hideMobileSearch(evt) {
      if (!evt) return;
      evt.stopPropagation();
      menuMovil.classList.remove('full-width');
      linksMenuMovil.classList.remove('hidden');
      utilsMovil.classList.remove('hidden');
    }

    /**
       * Funcion para tartar los toogle buttons del footer al cargar
       * la ventana dependiendo del tamaño de pantalla
       * @param {number} innerWidth  Ancho pantalla
       * @return {undefined}
       */
    function switchFooterToggle(innerWidth) {
      var footer = document.querySelector('.menuFooter');
      if (!footer) {
        footer = document.querySelector('.pre-footer');
        if (!footer) {
          return;
        }
      }

      var details = footer.querySelectorAll('details');
      if (!details || !details.length) {
        return;
      }
      for (const detail of Array.from(details)) {
        // Añade o quita el evento click en el titulo del desplegable
        if (innerWidth > 767) {
          if (!detail.open) {
            detail.open = true;
          }
        } else {
          detail.open = false;
        }
      }
    }

    return {
      init: function () {
        searchOpenButton = document.getElementsByClassName('header-main__search-dropdown-toggle')[0];
        menuMovil = document.querySelector('.main-nav');
        linksMenuMovil = document.getElementsByClassName('main-menu row')[0];
        utilsMovil = document.getElementsByClassName('main-menu--smartphone-utils')[0];

        switchSubmenu(window.innerWidth);
        switchFooterToggle(window.innerWidth);
        widthWindows = window.innerWidth;
        window.onresize = () => {
          if (((widthWindows <= 1199 && window.innerWidth > 1199) || (widthWindows > 1199 && window.innerWidth <= 1199))) {
            switchSubmenu(window.innerWidth);
          }
          if (((widthWindows <= 767 && window.innerWidth > 767) || (widthWindows > 767 && window.innerWidth <= 767))) {
            switchFooterToggle(window.innerWidth);
          }

          widthWindows = window.innerWidth;
        };
      },
    };
  }
);
