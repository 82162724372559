window.vg.exports(

  // Nombre del módulo
  'dom',

  // Factoria del módulo
  function () {
    return {
      /**
     * Convierte un elemento del dom en cuadrado
     * @param  {Element} domElement   Elemento a transformar
     * @return {undefined}
     */
      squarify: function squarify(domElement) {
        if (!domElement.classList.contains('square')) {
          domElement.classList.add('square');
          domElement.innerHTML =
          '<span class="square__content">' +
          domElement.innerHTML +
          '</span>';
        }
      },
    };
  }

);
