(function addPlayPauseToCarousel(vg) {
  /**
   * Comprobar si un carousel esta en pause
   * @param {Element} carousel - Elemento carousel
   * @return {Boolean} - Devuelve true si esta pausado y false en caso contrario
   */
  function isPaused(carousel) {
    return carousel.classList.contains('paused');
  }

  /**
   * Poner reproducción un carousel y cambiar la imagen del boton play/pause
   * @param {Element} carousel - Elemento del carousel
   * @param {Element} playPause - Elemento boton play/pause
   * @return {undefined}
   */
  function play(carousel, playPause) {
    carousel.Carousel.customPlay();
    // Pintar el boton de pausa para marcar la siguiente accion
    playPause.classList.remove('glyphicon-play');
    playPause.classList.add('glyphicon-pause');
  }

  /**
   * Poner en pausa un carousel y cambiar la imagen del boton play/pause
   * @param {Element} carousel - Elemento del carousel
   * @param {Element} playPause - Elemento boton play/pause
   * @return {undefined}
   */
  function pause(carousel, playPause) {
    carousel.Carousel.customPause();
    // Pintar el boton de play para marcar la siguiente accion
    playPause.classList.remove('glyphicon-pause');
    playPause.classList.add('glyphicon-play');
  }

  /**
   * Devuelve el carousel que contiene el elemento pasado.
   *
   * @param {Element} el elemento desde el que se busca
   * @param {String} parentClass clase CSS que identifica al carousel
   * @return {Element} Elemento DOM del Carousel
   */
  function findParentCarousel(el, parentClass) {
    while (!el.classList.contains(parentClass)) {
      el = el.parentNode;
      if (!el) {
        return;
      }
    }
    return el;
  }

  /**
   * Añadir la funcionalidad al hacer click en el boton play/pause
   *
   * @param {Element} carousel Carousel
   * @param {Element} playPause Boton play/pause
   * @return {undefined}
   */
  function addPlayPauseFunctionality(carousel, playPause) {
    playPause.addEventListener('click', function () {
      if (isPaused(carousel)) {
        play(carousel, playPause);
      } else {
        pause(carousel, playPause);
      }
    });
  }

  /**
   * Añadir la funcionalidad de swipe
   *
   * @param {Element} carousel Carousel
   * @return {undefined}
   */
  function addSwipeFunctionality(carousel) {
    var prev = carousel.querySelector('.carousel-prev');
    var next = carousel.querySelector('.carousel-next');
    carousel.addEventListener('swiped-left', function (e) {
      e.stopPropagation();
      next.click();
    });
    carousel.addEventListener('swiped-right', function (e) {
      e.stopPropagation();
      prev.click();
    });
  }

  // VG: Metodo propio para establecer el tabindex a -1 o quitarlo
  /* const setAriaHidden = (item, hidden) => {
    item.setAttribute('aria-hidden', hidden);
    const elementFocusables = item.querySelectorAll('button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)');
    elementFocusables.forEach((elemento) => {
      if (hidden) {
        elemento.setAttribute('tabindex', -1);
      } else {
        elemento.removeAttribute('tabindex');
      }
    });
  };*/
  /*
   * Añade la funcionalidad de play o pause a los caruosel
   */
  vg.ready(function () {
    var foundPlayPause = document.querySelectorAll('.play-indicators');
    for (var i = 0, max = foundPlayPause.length; i < max; i++) {
      var playPause = foundPlayPause[i];
      var parentCarousel = findParentCarousel(playPause, 'carousel');
      addPlayPauseFunctionality(parentCarousel, playPause);
      addSwipeFunctionality(parentCarousel);
    }

    // Si el carrusel sólo tiene un elemento, se marca el aria-live como off
    document.querySelectorAll('.carousel-inner').forEach((carousel) => {
      if (carousel.childElementCount == 1) {
        carousel.ariaLive = 'off';
        carousel.parentElement.querySelector('.carousel-indicators').classList.add('hidden-element');
      } else {
        carousel.querySelectorAll('.item > a').forEach((item) => {
          const parent = carousel.parentNode;
          const playpause = parent.querySelector('.play-indicators');

          item.addEventListener('focus', (event) => {
            pause(parent, playpause);
          });

          item.addEventListener('blur', (event) => {
            play(parent, playpause);
          });
        });
      }
    });
  });
})(window.vg);
