!function(global, document){
  var testElement = document.createElement('p');
  var _Element_prototype = global["Element"].prototype;
  if(!("children" in testElement) && !("firstElementChild" in testElement))_.push(function() {
    Object.defineProperty(_Element_prototype, "children", {"get" : function() {
      var arr = [],
        child = this.firstChild;
      while(child) {
        if(child.nodeType == 1)arr.push(child);
        child = child.nextSibling;
      }
      return arr;
    }});
  });
}(window, document);
