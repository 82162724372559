vg.exports(

  // Nombre del módulo
  'breadcrumb',

  // Factoria del módulo
  function moduleFactory() {
    const idioma = vg.utils.lang();
    const polyglot = new window.Polyglot();
    polyglot.extend({
      breadcrumb: {
        es: {
          arialabel: 'Dentro del sitio web, se encuentra en la siguiente página:',
          deprecated: 'Las migas de pan utilizadas están OBSOLETAS.',
        },
        eu: {
          arialabel: '(EU)Dentro del sitio web, se encuentra en la siguiente página:',
          deprecated: '(EU)Las migas de pan utilizadas están OBSOLETAS.',
        },
        en: {
          arialabel: '(EN)Dentro del sitio web, se encuentra en la siguiente página:',
          deprecated: '(EN)Las migas de pan utilizadas están OBSOLETAS.',
        },
        fr: {
          arialabel: '(FR)Dentro del sitio web, se encuentra en la siguiente página:',
          deprecated: '(FR)Las migas de pan utilizadas están OBSOLETAS.',
        },
      },
    });

    const breadcrumbProto = {
      node: null,
      init: function (navNode) {
        this.node = navNode;

        this.node.setAttribute('role', 'navigation');
        this.node.setAttribute('aria-label', polyglot.t(`breadcrumb.${idioma}.arialabel`));
      },
    };

    /**
     * Instancia una nueva instancia de Breadcrumb para el nav pasado por
     * parámetro.
     *
     * @param {Element} btn Elemento nav al que aplicar la funcionalidad
     * @return {BreadcrumbFactory} instancia generada
     */
    function breadcrumbFactory(btn) {
      var instance = Object.create(breadcrumbProto);
      instance.init(btn);
      return instance;
    }

    return {
      init: function () {
        var breadcrumbs = document.querySelectorAll('.breadcrumb');
        breadcrumbs.forEach((breadcrumb) => {
          if (breadcrumb.tagName == 'NAV') {
            breadcrumbFactory(breadcrumb);
          } else {
            console.log(polyglot.t(`breadcrumb.${idioma}.deprecated`));
          }
        });
      },
    };
  }
);


