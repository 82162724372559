// Para polyfills/picturefill.min.js
document.createElement('picture');

window.vg.ready(function () {
  var vg = window.vg;
  vg.backtotop.init();
  vg.buttons.init();
  vg.card.init();
  vg.fileset.init();
  vg.navBottom.init();
  vg.tablist.init();
  vg.inputDate.addDatepicker({
    nonSupportingBrowsersOnly: false,
  });
  vg.searchFilters.init();
  vg.submenu.init();
  vg.breadcrumb.init();
  vg.table.init();
  vg.readSpeaker.init();
  vg.summary.init();
});
