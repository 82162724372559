(function adaptarViejosBotonesShowHideDeSopra(vg) {
  var ESTADO_INICIAL_CAPA__VISIBLE = 'is-expanded';
  var ESTADO_INICIAL_CAPA__OCULTA = '';

  var CLASES_A_DESAPARECER = [
    'btn-show-green',
    'btn-show',
    'glyphicon',
    'glyphicon-minus',
    'glyphicon-plus',
  ];

  /**
   * Fuerza que un botón show/hide antiguo (los impuestos por Sopra) se adapte
   * a los nuevos estilos y utilice el mismo código JS que utilizan los botones
   * show / hide definidos en la guía de estilos.
   * @param  {Element|String} btn  Selector del boton
   * @param  {String} selectorCapa Selector de la capa asociada al botón
   * @param  {String} [estadoCapa] Estado inicial de la capa
   * @return {undefined}
   */
  function adaptarBotonShowHideSopra(btn, selectorCapa, estadoCapa) {
    var isSelector = Object.prototype.toString.call(btn) === '[object String]';
    if (isSelector) {
      btn = document.querySelector(btn);
    }
    if (!btn) return;

    estadoCapa = estadoCapa ||
      (btn.classList.contains('glyphicon-plus'))
      ? ESTADO_INICIAL_CAPA__OCULTA
      : ESTADO_INICIAL_CAPA__VISIBLE;

    btn.classList.add('btn-toggle', 'btn', estadoCapa);
    btn.classList.remove.apply(btn.classList, CLASES_A_DESAPARECER);

    if (!btn.innerHTML) {
      btn.innerHTML = 'mostrar / ocultar';
    }

    if (selectorCapa) {
      var capa = document.querySelector(selectorCapa);
      capa.classList.remove('hidden-element');
      capa.id = capa.id || vg.utils.uid();
      btn.setAttribute('aria-controls', capa.id);
    }
  }

  vg.ready(function () {
    // share comments
    adaptarBotonShowHideSopra(
      '.shareComments button.btn-show-green',
      '.shareComments .content-js');

    // comments received
    adaptarBotonShowHideSopra(
      '.commentsReceived button.btn-show-green',
      '.commentsReceived ul.comentarios');

    // menu cat aside
    adaptarBotonShowHideSopra(
      '.container-hide-cat .btn-show',
      '.container-hide-cat .hidden-element');

    // menu news aside
    adaptarBotonShowHideSopra(
      '.container-show-news .btn-show',
      '.container-show-news .hidden-element');

    // menu catplus aside
    adaptarBotonShowHideSopra(
      '.container-hide-pluscat .btn-show',
      '.container-hide-pluscat .hidden-element');

    // mailbox buttons
    adaptarBotonShowHideSopra(
      '.container-hide-mail .btn-show',
      '.container-hide-mail + .hidden-element');

    // Menus pie: home y sede
    (function () {
      var footerBtns = document.querySelectorAll('.menuFooter button');
      for (var i = 0, max = footerBtns.length; i < max; i++) {
        var btn = footerBtns[i];
        var layer = null;

        var nextSibling = btn.nextElementSibling;
        while (nextSibling) {
          if (nextSibling && nextSibling.tagName.toLowerCase() === 'nav') {
            layer = nextSibling;
          }
          nextSibling = nextSibling.nextElementSibling;
        }
        if (layer == null) {
          if (btn.parentNode.classList.contains('toolsMenu__header')) {
            var nextParentSibling = btn.parentNode.nextElementSibling;
            while (nextParentSibling) {
              if (nextParentSibling && nextParentSibling.tagName.toLowerCase() === 'nav') {
                layer = nextParentSibling;
              }
              nextParentSibling = nextParentSibling.nextElementSibling;
            }
          }
        }

        if (layer !== null) {
          adaptarBotonShowHideSopra(btn);
          layer.id = layer.id || vg.utils.uid();
          btn.setAttribute('aria-controls', layer.id);
          window.vg.buttons.toggleIconButtonFactory(btn);
        }
      }
    })();
  });
})(window.vg);

/**
 * Adapta el esquema beta para campos de formulario (se reordenaba con flex):
 *
 * <div class="field ...">
 *   <input class="field__input" ... >
 *   <label class="field__label">...</label>
 * </div>
 *
 * @param  {jQuery} $ Instancia de jQuery
 * @return {undefined}
 */
(function adaptarEsquemaPrimeraPropuestaFields(vg) {
  /**
   * Mueve el label antes de su control correspondiente
   * @param  {Element} label label a mover
   * @return {undefined}
   */
  function moveLabel(label) {
    var control = label.previousElementSibling;
    var parent = label.parent;
    parent.insertBefore(parent.removeChild(label), control);
  }

  /**
   * Recuperar todos los label que se encuentran detrás de su correspondiente
   * control de formulario
   * @return {NodeList} Array de elementos
   */
  function fetchLabels() {
    return document.querySelectorAll([
      '#ddCalles + .field__label',
      '#ddPortales + .field__label',
      '#ddPisos + .field__label',
      '.field__input + .field__label',
      '.field__textarea + .field__label',
      '.field__select + .field__label',
    ].join());
  }

  vg.ready(function () {
    var labels = fetchLabels();
    for (var i = 0, max = labels.length; i < max; i++) {
      moveLabel(labels[i]);
    }
  });
})(window.vg);

