(function autocomplete(window) {
  var $ = window.jQuery;
  var autocompletePluginDetected = ($ && $.ui && $.ui.autocomplete);
  if (!autocompletePluginDetected) return;

  var lang = document.documentElement.getAttribute('lang') || 'es';

  // A veces se carga el plugin jquery.facelist y se carga el autocomplete
  // En ese caso se cancela
  var isJqueryUiAutocomplete = (typeof $.Autocompleter === 'undefined');
  if (!isJqueryUiAutocomplete) {
    console.warn('No se recomienda utilizar el plugin jquery.facelist ' +
      'porque sobreescribe el autocomplete de jQueryUI');
    return;
  }

  /**
   * Personalizacion de autocomplete para que al navegar con teclado
   * no se sobreescriba el termino de busqueda de la caja.
   */
  if (autocompletePluginDetected) {
    $.widget('ui.autocompleteSolr', $.ui.autocomplete.prototype);
    $.extend($.ui.autocompleteSolr.prototype, {
      _move: function (e, t) {
        var val = this.element.val();
        /* eslint-disable */
        var ret = this.menu.element.is(':visible')
          ? this.menu.isFirstItem() && /^previous/.test(e) || this.menu.isLastItem() && /^next/.test(e)
            ? (this.isMultiLine || this.menu.blur(), undefined)
            : (this.menu[e](t), undefined)
          : (this.search(null, t), undefined);
        /* eslint-enable */
        this.element.val(val);
        return ret;
      },
    });
  }

  /**
   * Comprueba si el origen de datos de un autocomplete debe ser Solr
   * @param  {jQuery}  $input Campo al que se aplica el autocomplete
   * @return {Boolean}        true si es Solr, false en caso contrario
   */
  function isDataOriginSolr($input) {
    return $input.attr('data-autocomplete') === 'solr' ||
      $input.attr('data-autocomplete-origin') === 'solr';
  }

  /**
   * Comprueba si el origen de datos de un autocomplete es una variable global
   * @param  {jQuery}  $input Campo al que se aplica el autocomplete
   * @return {Object}        Devuelve la variable encontrada
   */
  function getDataOriginGlobarVar($input) {
    var origin = $input.attr('data-autocomplete-origin');
    if (typeof origin === 'undefined') return null;
    if (typeof window[origin] === 'undefined') return null;
    return window[origin];
  }

  /**
   * Aplica el widget de autocompletado para sugerencias de Solr
   * @param  {jQuery} $input Campo al que se aplica el autocomplete
   * @return {undefined}
   */
  function autocompleteSolr($input) {
    $input.autocompleteSolr({
      source: function (request, response) {
        $.ajax({
          type: 'GET',
          url: '/suggest?max_matches=10&use_similar=0',
          dataType: 'json',
          cache: false,
          data: {
            token: request.term,
            idioma: lang,
          },
          success: function (data) {
            response(data);
          },
          error: function (xhr, ajaxOptions, thrownError) {
            response('["error"]');
          },
        });
      },
      minLength: 3,
      blur: function (event, ui) {
        event.stopPropagation();
      },
      focus: function (event, ui) {
        event.stopPropagation();
      },
      select: function (event, ui) {
        $input.val(ui.item.label);
        $.ajax({
          type: 'GET',
          url: '/suggest',
          dataType: 'json',
          cache: false,
          data: {
            accion: 'ir',
            id: ui.item.value,
            idioma: lang,
          },
          success: function (data) {
            if (data.url) {
              window.location.assign(data.url);
            } else {
              let inputSubmit = $input.parent().next('[type ="submit"]');
              if (inputSubmit.length === 0) {
                inputSubmit = $input.next('[type ="submit"]');
              }
              inputSubmit.click();
            }
          },
          error: function (xhr, ajaxOptions, thrownError) {
            let inputSubmit = $input.parent().next('[type ="submit"]');
            if (inputSubmit.length === 0) {
              inputSubmit = $input.next('[type ="submit"]');
            }
            inputSubmit.click();
          },
        });
        return false;
      },
    });
  }

  /**
   * Aplica el widget de autocompletado estándar de jQuery UI
   * @param  {jQuery} $input Campo al que se aplica el autocomplete
   * @param  {String} source Datos de autocompletado
   * @return {undefined}
   */
  function autocompleteSource($input, source) {
    $input.autocomplete({
      minLength: 3,
      source: source,
      select: function (event, ui) {
        if (ui.item.url) {
          document.location.href = ui.item.url;
        } else {
          $input.val(ui.item.value);
          $input.parent().next('[type ="submit"]').click();
        }
      },
    });
  }

  $(function () {
    var $autocomplete = $('[data-autocomplete]');
    $autocomplete.each(function () {
      var $this = $(this);
      if (isDataOriginSolr($this)) {
        autocompleteSolr($this);
        return;
      }
      var source = getDataOriginGlobarVar($this);
      if (typeof source !== 'undefined') {
        autocompleteSource($this, source);
        return;
      }
    });
  });
})(window);
