/* eslint-disable max-len */
/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
window.vg.exports(

  // Nombre del módulo
  'searchFilters',

  // Factoria del módulo
  function moduleFactory() {
    var utils = window.vg.utils;
    /**
     * Funcion replicar los filtrados selecionados en el menu contextual
     * al principio del contenido de la pagina
     */
    function cloneSelectedFilters() {
      // var selectedFilters = document.querySelectorAll('.filter-selected');

      const selectedFilters = document.querySelectorAll('.area-result .linea__result > .btn.btn--nav');

      if (selectedFilters && selectedFilters.length > 0) {
        const divFiltrosSeleccionados = document.createElement('div');
        divFiltrosSeleccionados.id = `filtrosSeleccionados_${utils.uid()}`;
        divFiltrosSeleccionados.classList.add('filters-selected');
        divFiltrosSeleccionados.setAttribute('aria-live', 'assertive');
        divFiltrosSeleccionados.setAttribute('aria-atomic', 'true');
        divFiltrosSeleccionados.setAttribute('aria-relevant', 'additions');

        const resultsContainer = document.querySelector('.main__results');
        for (const selectedFilter of selectedFilters) {
          divFiltrosSeleccionados.appendChild(selectedFilter.cloneNode(true));
        }
        if (resultsContainer) {
          const child = resultsContainer.querySelector('.result-count');
          resultsContainer.insertBefore(divFiltrosSeleccionados, child.nextElementSibling);
        }
      }
    }
    return {
      init: function () {
        cloneSelectedFilters();
        const targetsfiltersSearch = document.querySelectorAll('.area-result > :is(h1,h2)');
        for (let i = 0, max = targetsfiltersSearch.length; i < max; i++) {
          window.vg.buttons.toogleSearchFiltersButtonFactory(targetsfiltersSearch[i]);
        }
      },
    };
  }
);
