/**
 * Modulo para definir alerts comunes para el sitio municipal.
 *
 * Los alert irán discriminados por tipo y se mostrarán en una lista de
 *
 * Métodos definidos por el módulo:
 *
 * - vg.alert.error(text[, options])
 * - vg.alert.warn(text[, options])
 * - vg.alert.info(text[, options])
 *
 * @author  David Marchena <ti.deustosistemas1@vitoria-gasteiz.org>
 * @version 1.0.0
 * date     2016-08-24
 */
(function alertQueue(vg) {
  var utils = vg.utils;

  var node = null;
  var stack = [];

  /**
   * Inicializar la capa que agrupa todas las alertas
   * @return {undefined}
   */
  function init() {
    node = document.createElement('ul');
    node.classList.add('alert-stack', 'alert-stack--top');
    node = document.body.appendChild(node);
  }

  // Métodos del módulo que podrían hacerse públicos.
  // De momento se dejan como privados
  var alertQueue = {
    /**
     * Devuelve el indice dentro de la cola del alert pasado por parámetro.
     * @param  {Object} alert   Alert buscado
     * @param  {Object} options Opciones de búsqueda:
     * {
     *   searchText  true | false (por defecto)
     * }
     * @return {Number} Indice dentro de la cola (0..length-1).
     * En caso de no estar, -1.
     */
    indexOf: function indexOf(alert, options) {
      options = Object.assign({}, {
        searchText: false,
      }, options);
      var compare = (options.searchText) ? alert.equals : alert.same;
      for (var i = 0, max = stack.length; i < max; i++) {
        if (compare(stack[i])) {
          return i;
        }
      }
      return -1;
    },
    /**
     * Añade un alert a la cola
     * @param  {Object} alert Alerta a añadir
     * @return {Object} Devuelve el mismo obj cola para encadenar instrucciones
     */
    push: function push(alert) {
      var self = this;
      var indexOfSameText = this.indexOf(alert, {searchText: true});
      if (indexOfSameText === -1) {
        alert.open({
          onclose: function () {
            self.remove(alert);
          },
        });
        stack.push(alert);
      } else {
        var previousAlert = (indexOfSameText > 0)
          ? stack[indexOfSameText - 1].node
          : undefined;
        stack[indexOfSameText].highlight({
          previousSibling: previousAlert,
        });
      }
      return this;
    },
    /**
     * Eliminar un alert de la cola si se encuentra dicha instacia en la misma..
     * @param  {Object} alert Alert a eliminar
     * @return {Object} Devuelve el mismo obj cola para encadenar instrucciones
     */
    remove: function (alert) {
      var indexOf = this.indexOf(alert);
      if (indexOf > -1) {
        stack.splice(indexOf, 1);
      }
      if (stack.length === 0) {
        node.remove();
        node = null;
      }
      return this;
    },
  };

  /**
   * Funcion que genera la función atajo para abrir un alert.
   * Los tipos admitidos son 'error', 'warn' e 'info'
   * @param  {String} type   Tipo de alert que abrirá la función resultado
   * @return {undefined}
   */
  function alertFunctionForType(type) {
    return function (text, options) {
      if (node===null) {
        // Inicialización del módulo alertQueue
        init();
      }
      // Extender las opciones por defecto
      options = Object.assign({
        parent: node,
        prepend: true,
      }, options);
      // Se añade type despues por si por un casual se especifica en options
      // Así se fuerza a que el type sea el definido en la funcion generadora
      options['type'] = type;
      // vg[moduleName].push(alertFactory(text, options));
      alertQueue.push(alertFactory(text, options));
    };
  }

  /* ---------------------------------------------
   *
   * Métodos PUBLICOS para definir alertas
   *
   * - vg.alert.error(text, options)
   * - vg.alert.warn(text, options)
   * - vg.alert.info(text, options)
   *
   * --------------------------------------------- */
  vg['alert'] = {
    error: alertFunctionForType('error'),
    warn: alertFunctionForType('warn'),
    info: alertFunctionForType('info'),
  };

  // ---------------------------------------------
  // Objeto Alert utilizado por alertQueue
  // ---------------------------------------------

  /**
   * Función que genera una alerta encapsulada en un objeto independiente y
   * funcional
   * @param  {String} text    Texto o HTML que contendra el alert
   * @param  {Object} options Opciones de configuracion:
   * {
   *   $parent:    $node,
   *   autoClose:  true | false (por defecto),
   *   prepend:    true | false (por defecto),
   *   type:       'error' (por defecto), 'warn', 'info'
   * }
   * @return {Object} Objeto JS que representa el alert
   */
  function alertFactory(text, options) {
    var node = null;

    var defaultConfig = {
      autoClose: false,
      prepend: false,
      type: 'error',
    };
    var config = Object.assign({}, defaultConfig, options);

    // Identificadores del objeto devuelto
    var contentHash = utils.hash(text); // Id segun contenido para comparaciones
    var uid = utils.uid(); // Unico de la instancia devuelta

    var autoCloseInterval = null;

    return {
      /**
       * Devuelve el objeto Element que representa a la alerta
       * @return {Element} Elemento DOM de la alerta
       */
      node: function () {
        return node;
      },
      /**
       * Devuelve true si el alert es la misma instancia que la pasada por
       * parametro.
       * False en caso contrario
       * @param  {Object} alert Alert con el que comparar
       * @return {Boolean} resultado
       */
      same: function (alert) {
        return uid === alert.getUid();
      },
      /**
       * Devuelve true si el alert es equivalente (contiene el mismo mensaje y
       * es del mismo tipo) que el pasado por parámetro.
       * @param  {Object} alert Alert con el que comparar
       * @return {Boolean} resultado
       */
      equals: function (alert) {
        return contentHash === alert.getContentHash() &&
          config.type === alert.getType();
      },
      /**
       * Devuelve el hash del mensaje contenido por el alert.
       * Utilidad para el la funcion 'equals'.
       * @return {String} Hash
       */
      getContentHash: function getContentHash() {
        return contentHash;
      },
      /**
       * Devuelve el tipo del mensaje del alert.
       * Utilidad para el la funcion 'equals'.
       * @return {String} Type
       */
      getType: function getType() {
        return config.type;
      },
      /**
       * Devuelve el UID del alert.
       * Utilidad para la función 'same'.
       * @return {String} UID
       */
      getUid: function getUid() {
        return uid;
      },
      /**
       * Destacar mediante un efecto temporal la alerta.
       * @param  {Object} options Opciones:
       * {
       *   $previousSibling    jQueryObject de la alerta anterior en la cola
       *                       (si la hay)
       * }
       * @return {Object} El propio Alert para encadenamiento
       */
      highlight: function highlight(options) {
        options = options || {};
        var parent = config.parent;
        node = parent.removeChild(node);
        if (config.prepend === true) {
          if (options.previousSibling && options.previousSibling.length > 0) {
            parent.insertBefore(node, options.previousSibling);
          } else {
            // El primero de la lista es el último del UL
            parent.appendChild(node);
          }
        } else {
          /* eslint-disable */
          if (options.$previousSibling && options.$previousSibling.length > 0) {
            parent.insertBefore(node, options.previousSibling.nextSibling);
          } else {
            // El primero de la lista es el primero del UL
            parent.insertBefore(node, parent.childNodes[0]);
          }
          /* eslint-enable */
        }
        node.classList.remove('alert--shake');
        node.classList.add('alert--shake');
        return this;
      },
      /**
       * Abrir una alerta
       * @param  {Object} options Ampliar las opciones de configuracion:
       *                  {
       *                    $parent:    $node,
       *                    autoClose:  true | false (por defecto),
       *                    prepend:    true | false (por defecto),
       *                    type:       'error' (por defecto), 'warn', 'info'
       *                  }
       * @return {Object}         El propio Alert para encadenamiento
       */
      open: function open(options) {
        Object.assign(config, options);
        var self = this;
        node = document.createElement('li');
        node.setAttribute('tabindex', '-1');
        node.setAttribute('role', 'alert');
        node.classList.add('alert', 'alert--' + config.type);

        var esInteractivo =
          !(text.indexOf('<a') === -1 &&
          text.indexOf('<input') === -1 &&
          text.indexOf('<button') === -1);
        if (esInteractivo) {
          // No es accesible usar role='alert' con interaccion
          node.innerText = text;
        } else {
          node.innerHTML = text;
        }
        if (config.prepend === true) {
          config.parent.insertBefore(node, config.parent.childNodes[0]);
        } else {
          config.parent.appendChild(node);
        }
        node.addEventListener('click', function () {
          self.close();
        });
        // opened = true;

        if (config.autoClose && autoCloseInterval === null) {
          autoCloseInterval = setInterval(function () {
            self.close();
            if (autoCloseInterval !== null) {
              clearInterval(autoCloseInterval);
              autoCloseInterval = null;
            }
          }, 7000);
        }
        return this;
      },
      /**
       * Cierra el alert
       * @return {Object} El propio Alert para encadenamiento
       */
      close: function close() {
        config.parent.removeChild(node);
        // opened = false;
        if (typeof config.onclose === 'function') {
          config.onclose.call();
        }
        return this;
      },
    };
  }
})(window.vg);
