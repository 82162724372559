(function dialogModule(window) {
  var $ = window.jQuery;
  if ($ === undefined || $.ui === undefined) return;

  var moduleName = 'dialog';
  var utils = window.vg.utils;

  const idioma = vg.utils.lang();
  var polyglot = new window.Polyglot();
  polyglot.extend({
    dialog: {
      es: {
        close: 'Cerrar',
        accept: 'Aceptar',
        cancel: 'Cancelar',
      },
      eu: {
        close: 'Itxi',
        accept: 'Onartu',
        cancel: 'Utzi',
      },
      en: {
        close: 'Close',
        accept: 'Accept',
        cancel: 'Cancel',
      },
      fr: {
        close: 'Fermer',
        accept: 'Accepter',
        cancel: 'Annuler',
      },
    },
  });

  /**
   * Genera un objeto muy similar al HistoryAPI de HTML5 para almacenar
   * estados de un componente y posibilitar volver a estados anteriores.
   * @return {Object} Objeto History
   */
  function historyFactory() {
    var states = [];

    return {
      state: -1,
      /**
       * Almacenar la información asociada con un estado de un componente.
       * @param  {Object} stateObj Información referente a un nuevo estado
       * @return {undefined}
       */
      pushState: function pushState(stateObj) {
        states.push(stateObj);
      },
      /**
       * Devolver la información del estado anterior (el último almacenado)
       * y eliminarla del historial.
       * @return {Object} Información asociada al último estado
       */
      back: function back() {
        return states.pop();
      },
    };
  }

  var flowFactory = (function stepperPromisesFlow() {
    /**
     * Generar un paso que irá dentro de un proceso que comprende varios
     * pasos antes de completarse.
     * @return {Object} Object Step
     */
    function stepFactory() {
      var actions;
      var deferred;
      var promises;

      /**
       * Dejar la información del paso como al principio y lista para
       * añadir acciones.
       * @return {undefined}
       */
      function reset() {
        deferred = $.Deferred();
        actions = [];
        promises = [];
      }

      /**
       * Añadir la promesa de final de una acción. La promesa se encadenará
       * a la anterior promesa registrada para generar un flujo lógico de
       * acciones asíncronas.
       * @param  {Function} action Función que determina la acción
       * @return {undefined}
       */
      function pushPromiseFromAction(action) {
        var prevPromise = (promises.length === 0)
          ? deferred.promise()
          : promises[promises.length - 1];
        promises.push(prevPromise.then(action));
      }

      reset();

      return {
        pushAction: function pushAction(fn) {
          actions.push(fn);
          pushPromiseFromAction(fn);
          return this;
        },
        resolve: function resolve(value, done) {
          if (typeof done === 'function') {
            $.when.apply($, promises).then(done);
          }
          return deferred.resolve(value);
        },
        reload: function reload() {
          deferred = $.Deferred();
          promises = [];
          for (var i = 0, max = actions.length; i < max; i++) {
            pushPromiseFromAction(actions[i]);
          }
          return this;
        },
      };
    }

    /**
     * Genera un nuevo objeto que representa el flujo de proceso que está
     * dividido en varios pasos.
     * @return {Object} Object Flow
     */
    function flowFactory() {
      var onready;
      var steps;
      var lastStep;
      var nextStep;

      /**
       * Dejar la información del proceso como al principio y lista para
       * añadir pasos.
       * @return {undefined}
       */
      function reset() {
        onready = stepFactory();
        steps = [];
        lastStep = onready;
        nextStep = -1;
      }

      reset();

      return {
        pushAction: function pushAction(fn) {
          lastStep.pushAction(fn);
          return this;
        },
        pushStep: function pushStep() {
          lastStep = stepFactory();
          steps.push(lastStep);
          return this;
        },
        length: function length() {
          return (steps) ? steps.length : 0;
        },
        hasNext: function hasNext() {
          return nextStep < steps.length - 1;
        },
        hasPrev: function hasPrev() {
          return nextStep > -1;
        },
        next: function next(value, done) {
          if (!this.hasNext()) return false;
          nextStep++;
          steps[nextStep].resolve(value, done);
          return this;
        },
        prev: function prev() {
          if (!this.hasPrev()) return false;
          if (nextStep > -1) {
            steps[nextStep].reload();
            nextStep--;
          }
          return this;
        },
        ready: function ready() {
          onready.resolve();
          return this;
        },
        reset: function () {
          reset();
          return this;
        },
      };
    }

    return flowFactory;
  })();

  /**
   * Genera un Dialog abstracto (debe de extenderse)
   * @param  {Object} options Opciones del Dialog
   * @return {Object}         Object abstract Dialog
   */
  function dialogFactory(options) {
    if (!options || !options.content) {
      throw new Error('Parametros obligatorios no incluidos.');
    }

    var defaultOptions = {
      buttonBack: polyglot.t(`dialog.${idioma}.cancel`),
      buttonContinue: polyglot.t(`dialog.${idioma}.accept`),
      closeText: polyglot.t(`dialog.${idioma}.close`),
      title: '&nbsp;',
      fnClose: function close(dialog) {
        dialog.dialog('close');
      },
      fnContinue: function dummy() {},
    };
    options = Object.assign({}, defaultOptions, options);
    options['fnBack'] = options.fnBack || options.fnClose;

    var content = options.content;
    var title = options.title;
    var titleClose = options.closeText;
    var uid = utils.hash(content);

    var $dialog;
    var $content;
    var $buttonBack;
    var $buttonContinue;
    var $title;

    var dialogFlow = flowFactory();
    var dialogHistory = historyFactory();

    /**
     * Pone el foco en el primer elemento de formulario que haya en el
     * contenido en caso de haberlo. En otro caso lo establece en el boton
     * de continuar u otro pasado por parámetro.
     * @param  {$.fn} [$buttonClicked=$buttonContinue] - Boton sobre el que
     * se pondrá el foco si no hay elementos de formulario.
     * @return {undefined}
     */
    function focusContent($buttonClicked) {
      var formControlsSelector = "input[type!='hidden'],select,textarea";
      var $formControlToFocus = $content.find(formControlsSelector).first();
      if ($formControlToFocus.length > 0) {
        $formControlToFocus.focus();
      } else {
        $buttonClicked = $buttonClicked || $buttonContinue;
        $buttonClicked.focus();
      }
    }

    /**
     * Instanciar el jQueryUI Dialog que representará al objeto en pantalla y
     * añadirle ciertas mejoras de accesibilidad.
     * @return {jQueryObject} Objeto jQuery devuelto por $.fn.dialog().
     */
    function createDialog() {
      var $found = $('#' + uid);
      if ($found.length > 0) $found.dialog('destroy').remove();

      var $newDialog = $(
        ['<div id="', uid, '" title="', title, '">', content, '</div>'].join('')
      ).appendTo($('body'));

      $newDialog.dialog({
        autoOpen: false,
        beforeClose: function (event) {
          if (event.originalEvent) {
            // X button or ESC
            event.preventDefault();
            options.fnClose($newDialog, dialogFlow);
          }
        },
        resizable: false,
        draggable: false,
        height: 'auto',
        width: '80%',
        modal: true,
        buttons: [
          {
            text: options.buttonBack,
            click: function () {
              state(dialogHistory.back());
              focusContent($buttonBack);
              options.fnBack($newDialog, dialogFlow);
            },
          },
          {
            text: options.buttonContinue,
            click: function () {
              dialogHistory.pushState(state());
              options.fnContinue($newDialog, dialogFlow);
            },
          },
        ],
        closeText: titleClose,
      });

      $dialog = $newDialog;
      $content = $newDialog;
      $buttonBack = $newDialog.next().find('.ui-button').first();
      $buttonBack[0].classList.add('ui-button--secundary');
      $buttonContinue = $buttonBack.next();
      $buttonContinue[0].classList.add('ui-button--primary');
      $title = $newDialog.prev().find('.ui-dialog-title');

      // Accesibilidad del cambio del contenido
      $content.attr('aria-live', 'assertive');
      $content.on('keydown', function keynavModeOn(evt) {
        var KEY_ENTER = 13;
        var key = window.event ? evt.keyCode : evt.which;
        switch (key) {
          case KEY_ENTER:
            evt.stopPropagation();
            evt.preventDefault();
            $buttonContinue.focus().click();
            break;
          default:
        }
      });

      return $dialog;
    }

    /**
     * Devuelve el HTML del contenido del dialogo pero incluyendo en el
     * código todos los valores que un usuario haya podido introducir
     * en controles de formulario.
     * @return {String} HTML del contenido del dialogo actualizado
     */
    function getContentHtmlWithUpdatedFormValues() {
      var $updatedContent = $content.clone();
      $updatedContent.find('input,select,textarea').each(function () {
        if ($(this).is(':checkbox, :radio')) {
          $(this).attr('checked', $(this).attr('checked'));
        } else {
          $(this).attr('value', $(this).val());
        }
      });
      return $updatedContent.html();
    }

    /**
     * Devuelve la información de estado del Dialog. Si se pasa por parámetro
     * un Object establece la información del Dialog con los datos del mismo.
     * @param  {Object} [stateObj] - Información de un estado del Dialog a
     * reestablecer.
     * @return {object} - Object con la información del estado actual del
     * Dialog en caso de no haber pasado ningún parametro a la funcion.
     * Undefined en caso contrario.
     */
    function state(stateObj) {
      var isObject = typeof stateObj !== 'undefined' &&
        Object.prototype.toString.call(stateObj) === '[object Object]';
      if (isObject) {
        if (stateObj.buttonBack) {
          $buttonBack.find('.ui-button-text').text(stateObj.buttonBack);
        }
        if (stateObj.buttonContinue) {
          $buttonContinue.find('.ui-button-text').text(stateObj.buttonContinue);
        }
        if (stateObj.content) {
          $content.html(stateObj.content);
        }
        if (stateObj.title) {
          $title.text(stateObj.title);
        }
      } else {
        // getState
        return {
          content: getContentHtmlWithUpdatedFormValues(),
          title: $title.text(),
          buttonBack: $buttonBack.find('.ui-button-text').text(),
          buttonContinue: $buttonContinue.find('.ui-button-text').text(),
        };
      }
    }

    return {
      close: function close() {
        options.fnClose($dialog, dialogFlow);
        return this;
      },
      open: function open() {
        createDialog();
        $dialog.dialog('open');
        dialogHistory.pushState(state());
        dialogFlow.reset();
        return this;
      },
      /*
       * Flujo del dialog
       * --------------------------------------------------------- */
      then: function then(fn) {
        dialogFlow.pushAction(fn);
        return this;
      },
      wait: function wait() {
        dialogFlow.ready();
        dialogFlow.pushAction(focusContent);
        dialogFlow.pushStep();
        return this;
      },
      /*
       * Funciones para manejar textos y contenidos
       * --------------------------------------------------------- */
      buttonContinue: function buttonContinue(text) {
        if (typeof text === 'undefined') return $buttonContinue;
        var $textContainer = $buttonContinue.find('.ui-button-text');
        $textContainer.text(text);
        return this;
      },
      content: function content(html) {
        if (typeof html === 'undefined') return $content;
        $content.html(html);
      },
      title: function title(text) {
        if (typeof text === 'undefined') return $title;
        $title.text(text);
      },
    };
  }

  /**
   * Devuelve un Dialog de tipo Confirm listo para usar. Este tipo de Dialog
   * consiste en una réplica asíncrona del window.confirm de Javascript.
   * @param  {Object} options Opciones del Dialog
   * @return {Object}         Object Dialog Confirm
   */
  function confirmFactory(options) {
    options = Object.assign({}, options, {
      fnClose: function (dialog, dialogFlow) {
        dialogFlow.next(false, function () {
          dialog.dialog('close');
        });
      },
      fnContinue: function (dialog, dialogFlow) {
        dialogFlow.next(true, function () {
          dialog.dialog('close');
        });
      },
    });
    return dialogFactory(options);
  }

  /**
   * Devuelve un Dialog de tipo Stepper listo para usar. Este tipo de Dialog
   * se utiliza para incluir dentro de un mismo Dialog un proceso con varias
   * pantallas sucesivas.
   * @param  {Object} options Opciones del Dialog
   * @return {Object}         Object Dialog Stepper
   */
  function stepperFactory(options) {
    options = Object.assign({
      buttonContinue: 'siguiente',
    }, options, {
      fnBack: function (dialog, dialogFlow) {
        if (dialogFlow.hasPrev()) {
          dialogFlow.prev();
        } else {
          dialog.dialog('close');
        }
      },
      fnContinue: function (dialog, dialogFlow) {
        dialogFlow.next(true, function () {
          if (dialogFlow.hasNext()) {
            dialog.next().find('.ui-button-text').first().text('anterior');
          } else {
            dialog.dialog('close');
          }
        });
      },
    });
    return dialogFactory(options);
  }

  window.vg[moduleName] = {
    confirm: function (content, options) {
      return this.createConfirm(content, options).open().wait();
    },
    createConfirm: function (content, options) {
      options = Object.assign({content: content}, options);
      return confirmFactory(options);
    },
    createStepper: function (content, options) {
      options = Object.assign({content: content}, options);
      return stepperFactory(options);
    },
  };
})(window);
