/* eslint-disable valid-jsdoc */
/* eslint-disable require-jsdoc */
(function smartphoneMenu(vg) {
  // var utils = vg.utils;
  /**
   * Inicializa la funcionalidad del menu movil
   */
  function init() {
    var smartphoneNav = document.querySelector('.main-nav');
    var smartphoneNavBtn;
    var smartphoneNavOverlay;
    // var smartphoneNavFirstFocus;
    var textoCerrarMenu;

    /**
     * Devuelve el primer elemento que admite focus dentro del elemento que se
     * pasa como parámetro
     * @param {Element} parent Elemento dentro del cual se buscará
     * @return {Element|NullFocusableObject} Elemento (si existe) o null si no
     * hay ninguno
     */
    /* function queryFirstFocusable(parent) {
      var result = parent.querySelector([
        'button, ',
        '[href], ',
        'input:not([type="hidden"]), ',
        'select, ',
        'textarea, ',
        '[tabindex]:not([tabindex="-1"])',
      ].join(''));
      if (result === null) {
        result = {
          focus: function dummyFocus() {},
        };
      }
      return result;
    } */

    /**
     * Funcion para añadir el boton de cerrar en
     * la capa del overlay
     */
    function addBotonCerrarOverlay() {
      var smartphoneNavClose = '<div class="main-nav-overlay-close">' +
      '<button class="btn-menu-close">' +
      '<span class="btn-menu-close__icon"></span>' +
      '<span class="btn-menu-close__text">' +
      textoCerrarMenu +
      '</span>' +
      '</button>';

      smartphoneNavOverlay
        .insertAdjacentHTML('afterbegin', smartphoneNavClose);
    }


    /**
     * Funcion para cargar diferentes acciones para el
     * menu de turismo y www
     * @param {String} className parametro para definir el nombre de
     * la clase a buscar
     */
    function cargarMenuParaTurismo(className) {
      var menuItems = document.querySelectorAll(className);
      var subMenuItems = document.querySelectorAll('.dropdown-menu__item');
      var navSubMenuItems = [].slice.call(subMenuItems);

      navSubMenuItems.forEach(function (elemento) {
        elemento.addEventListener('click', function (e) {
          e.stopPropagation();
        });
      });
      var subMenuItemsAction = document.querySelectorAll('.main-menu__item-action.dropdown-toggle');
      var navSubMenuItemsAction = [].slice.call(subMenuItemsAction);
      navSubMenuItemsAction.forEach(function (elemento) {
        if (!elemento.classList.contains('main-menu__item-action--www') && !elemento.classList.contains('main-menu__item-action--sede')) {
          elemento.setAttribute('aria-haspopup', true);
        }
        elemento.setAttribute('aria-expanded', false);

        elemento.addEventListener('click', function (e) {
          smartphoneNav.scrollTop = 0;
          smartphoneNav.classList.add('main-nav-overflow--hidden');
        });
      });

      var navExpand = [].slice.call(menuItems);
      navExpand.forEach(function (item) {
        var dropdowMenu = item.querySelector('.dropdown-menu');
        dropdowMenu.setAttribute('aria-hidden', true);
        dropdowMenu.hidden = true;
        var textoBoton = dropdowMenu.previousElementSibling.textContent;

        var liback = document.createElement('div');
        liback.classList.add('dropdown-menu__item', 'dropdown-menu__item-back');

        var aback = document.createElement('span');
        aback.role='link';
        aback.tabIndex='0';
        aback.className = 'dropdown-menu__item-action';
        aback.innerHTML = textoBoton;
        aback.addEventListener('click', function (e) {
          e.preventDefault();
          smartphoneNav.classList.remove('main-nav-overflow--hidden');
          dropdowMenu.setAttribute('aria-hidden', true);
        });

        liback.appendChild(aback);
        dropdowMenu.insertBefore(liback, dropdowMenu.firstChild);
      });

      addBotonCerrarOverlay();
    }

    /**
     * Funcion para cargar los elementos para cambiar de
     * idioma en móvil
     * * @return {undefined}
     */
    function cargarIdiomasEnMenuMovil() {
      const idioma = window.vg.utils.lang();
      const polyglot = new window.Polyglot();
      polyglot.extend({
        idioma: {
          es: 'Idioma',
          eu: 'Hizkuntza',
          en: 'Language',
          fr: 'Langue'}});

      const firstItem = document.querySelector('.main-menu--smartphone-utils > li.main-menu__item:not(.main-menu__item--shortcut)');

      const breakItem = document.createElement('li');
      breakItem.classList.add('dropdown-menu__item-break');

      const liLang = document.createElement('li');
      liLang.classList.add('main-menu__item', 'main-menu__item--lang', 'dropdown');

      const buttonLang = document.createElement('button');

      buttonLang.classList.add('main-menu__item-action', 'dropdown-toggle');
      buttonLang.setAttribute('data-toogle', 'dropdown');
      buttonLang.setAttribute('aria-haspopup', true);
      buttonLang.textContent = polyglot.t(`idioma.${idioma}`);
      buttonLang.addEventListener('click', function (e) {
        e.preventDefault();
        smartphoneNav.scrollTop = 0;
        smartphoneNav.classList.add('main-nav-overflow--hidden');
        liLang.classList.add('open');
        buttonLang.setAttribute('aria-expanded', true);
        divLang.setAttribute('aria-hidden', false);
        divLang.hidden = false;
      });

      liLang.appendChild(buttonLang);

      const divLang = document.createElement('div');
      divLang.classList.add('dropdown-menu', 'submenu');
      divLang.setAttribute('aria-hidden', true);
      divLang.hidden = true;

      const dropdownBack = document.createElement('div');
      dropdownBack.classList.add('dropdown-menu__item', 'dropdown-menu__item-back');

      const aBack = document.createElement('span');
      aBack.role='link';
      aBack.tabIndex='0';
      aBack.classList.add('dropdown-menu__item-action');
      aBack.textContent = polyglot.t(`idioma.${idioma}`);
      aBack.addEventListener('click', function (e) {
        e.preventDefault();
        smartphoneNav.classList.remove('main-nav-overflow--hidden');
        liLang.classList.remove('open');
        buttonLang.setAttribute('aria-expanded', false);
        divLang.setAttribute('aria-hidden', true);
        divLang.hidden = true;
      });


      dropdownBack.appendChild(aBack);

      divLang.appendChild(dropdownBack);

      const subMenuLang = document.createElement('div');
      subMenuLang.classList.add('submenu__list');

      const ulSubmenu = document.createElement('ul');

      const idiomaMenu = document.querySelector('.header-utils__lang-menu');
      if (idiomaMenu !== null) {
        const idiomas = idiomaMenu.querySelectorAll('li');
        idiomas.forEach((child)=> {
          const itemSubMenu = document.createElement('li');
          itemSubMenu.classList.add('dropdown-menu__item');

          const itemSubMenuA = document.createElement('span');
          itemSubMenuA.role='link';
          itemSubMenuA.tabIndex='0';
          itemSubMenuA.classList.add('dropdown-menu__item-action');
          if (child.firstChild.lang === idioma ) {
            itemSubMenuA.classList.add('is-active');
          }
          itemSubMenuA.textContent = child.firstChild.textContent;
          itemSubMenu.addEventListener('click', ()=> {
            child.firstChild.click();
          });

          itemSubMenu.appendChild(itemSubMenuA);
          ulSubmenu.appendChild(itemSubMenu);
        });


        subMenuLang.appendChild(ulSubmenu);

        divLang.appendChild(subMenuLang);

        liLang.appendChild(divLang);

        if (firstItem !== null) {
          firstItem.parentNode.insertBefore(liLang, firstItem);
          firstItem.parentNode.insertBefore(breakItem, liLang);
        } else {
          const utilssede = document.querySelector('.header-menu__utils');
          utilssede.insertBefore(liLang, utilssede.firstChild);
        }
      }
    }

    /**
     * Funcion para cargar el texto de cerrar menu
     * en el idioma correspondiente
     * @return {undefined}
     */
    function cargarTextoCerrarIdioma() {
      var idioma = document.documentElement.lang;

      textoCerrarMenu = 'Cerrar menú';
      if (idioma === 'eu') {
        textoCerrarMenu = 'Itxi menu';
      }
      if (idioma === 'en') {
        textoCerrarMenu = 'Close menu';
      }
      if (idioma === 'fr') {
        textoCerrarMenu = 'Fermer le menu';
      }
    }

    /**
     * Funcion para hacer visible/invisible el menu
     * @param  {Event} evt   Evento que lo invoca
     * @return {undefined}
     */
    function toggleMenu(evt) {
      evt.stopPropagation();
      var expand = smartphoneNavBtn.getAttribute('aria-expanded') === 'false';
      smartphoneNavBtn.setAttribute('aria-expanded', expand);

      // Ocultar campo de busqueda
      var searchField = document.querySelector('.main-nav__search-form');
      // Solo para el entorno www
      var entornoWww = document.querySelector('.entorno-www');
      if (searchField && entornoWww) {
        searchField.hidden = true;
        searchField.style.height = '0px';
      }
      var searcher = document.querySelector('.field--search');
      searcher.hidden = true;
      // Restarurar el boton de busqueda
      var searchToggle = document.querySelector('.header-main__search-dropdown-toggle');
      var searchMenu = document.querySelector('.header-main__search-menu');
      const linksMenuMovil = document.querySelector('.main-menu.row');
      // Comprobacion de que se usa el buscador o el menu
      if (searchMenu && ((searchMenu.classList.contains('is-active') || searchField.hidden) &&
       linksMenuMovil.classList.contains('hidden'))) {
        /* Se llama a la funcion que cambia
          el estado del boton y lo escucha c-submenu-js
         */
        searchToggle.click();
      } else {
        smartphoneNav.classList.toggle('main-nav--visible');
        smartphoneNavOverlay.classList.toggle('main-nav-overlay--visible');
        smartphoneNav.setAttribute('aria-hidden', !expand);
        smartphoneNav.hidden = !smartphoneNav.hidden;

        if (expand) {
          document.documentElement.classList.add('scroll-disabled');
        } else {
          document.documentElement.classList.remove('scroll-disabled');
        }
      }
    }

    /**
   * Añade un evento swiped al documento para desplegar el menú
   * @param  {Element} boton    Element del botón generado
   * @param  {Element} menu     Element del menu generado
   */
    function generarEventoSwiped(boton, menu) {
      document.addEventListener('swiped-right', function (e) {
        e.stopPropagation();
        // boton.click();
      });
      menu.addEventListener('swiped-left', function (e) {
        e.stopPropagation();
        boton.click();
      });
    }

    if (smartphoneNav !== null) {
      cargarTextoCerrarIdioma();
      cargarIdiomasEnMenuMovil();
      // smartphoneNavFirstFocus = queryFirstFocusable(smartphoneNav);

      var hidden = window.innerWidth < '1199';
      smartphoneNav.setAttribute('aria-hidden', hidden);
      smartphoneNav.setAttribute('hidden', hidden);

      smartphoneNavBtn = document.getElementById('open-menu');
      smartphoneNavBtn.setAttribute('aria-expanded', false);
      smartphoneNavBtn.setAttribute('aria-controls', 'open-menu');

      smartphoneNavOverlay = document.createElement('div');
      smartphoneNavOverlay.setAttribute('role', 'button');
      smartphoneNavOverlay.setAttribute('aria-label', textoCerrarMenu);
      smartphoneNavOverlay.classList.add('main-nav-overlay');
      smartphoneNavOverlay = smartphoneNav.parentNode
        .appendChild(smartphoneNavOverlay);

      smartphoneNavBtn.addEventListener('click', toggleMenu);
      smartphoneNavOverlay.addEventListener('click', toggleMenu);

      generarEventoSwiped(smartphoneNavBtn, smartphoneNav);

      if (document.body.classList.contains('entorno-turismo')) {
        cargarMenuParaTurismo('.main-menu__item--turismo');
      } else if (document.body.classList.contains('entorno-www')) {
        cargarMenuParaTurismo('.main-menu__item--www');
      } else if (document.body.classList.contains('entorno-teatro')) {
        cargarMenuParaTurismo('.main-menu__item--teatro');
      } else if (document.body.classList.contains('entorno-congresos') ) {
        cargarMenuParaTurismo('.main-menu__item--congresos');
      } else if (document.body.classList.contains('entorno-sede') ) {
        addBotonCerrarOverlay();
      }
    }

    /**
   * Cambia de sitio  la lsita de redes sociales
   */
    var listaRedes = document.querySelector('.networks');
    var containerRedes = document.querySelector('.networks-container');
    var menuFooter = document.querySelector('.menuFooter');
    if (listaRedes !== null && containerRedes !== null) {
      containerRedes.appendChild(listaRedes);
      menuFooter.removeChild(menuFooter.lastChild);
      menuFooter.removeChild(menuFooter.lastChild);
    }
  }

  // Script for initializing the sliding menu.
  vg.ready(function () {
    init();
  });
})(window.vg);
