window.vg.exports(

  // Nombre del módulo
  'privacy',

  // Factoria del módulo
  function moduleFactory() {
    var utils = window.vg.utils;
    var cookieNameConf = 'privacidadConfiguracion';
    var cookieNameTerc = 'privacidadTerceros';
    var cookieNamePubli = 'privacidadPublicitarias';
    var avisoCookies;
    var frmAvisoCookies;
    var idDivTerc;
    var idChkTerc;
    var idDivPubli;
    var idChkPubli;
    var btnOk;
    var btnAceptar;
    var btnRechazar;
    var mostrado = false;
    var urlLocal = 'https://localhost.vitoria-gasteiz.org/wb021/was/PrivacidadServlet';
    var urlDesa = 'https://dwww.vitoria-gasteiz.org/wb021/was/PrivacidadServlet';
    var urlPre = 'https://pwww.vitoria-gasteiz.org/wb021/was/PrivacidadServlet';
    var urlProd = 'https://www.vitoria-gasteiz.org/wb021/was/PrivacidadServlet';
    var cookiesUpdateOk =
      'Se ha cambiado la configuración de cookies correctamente.';
    var cookiesUpdateError =
      'Error: No se ha podido cambiar la configuración de cookies.';

    /**
     * Comprobar si la privacidad está aceptada
     * @param  {string} idioma Idioma de navegación
     * @return {boolean} Devuelve si está aceptada o no
     */
    function isConfigurationPrivacyAccepted(idioma) {
      if (docCookies.hasItem(cookieNameConf)) {
        if (docCookies.getItem(cookieNameConf) === 'aceptada') {
          return true;
        }
      }
      printPrivacityForm(idioma);
      return false;
    }

    /**
     * Comprobar si la privacidad está aceptada
     * @param  {string} idioma Idioma de navegación
     * @return {boolean} Devuelve si está aceptada o no
     */
    function isThirdPartyPrivacyAccepted(idioma) {
      if (docCookies.hasItem(cookieNameTerc)) {
        if (docCookies.getItem(cookieNameTerc) === 'aceptada') {
          return true;
        }
        return false;
      }
      if (utils.isIOS() && utils.isInStandalone()) {
        console.log('iOs en standalone.');
      } else {
        printPrivacityForm(idioma);
      }
      return false;
    }

    /**
     * Comprobar si la privacidad publicitaria está aceptada
     * @param  {string} idioma Idioma de navegación
     * @return {boolean} Devuelve si está aceptada o no
     */
    function isPublicitaryPrivacyAccepted(idioma) {
      if (docCookies.hasItem(cookieNamePubli)) {
        if (docCookies.getItem(cookieNamePubli) === 'aceptada') {
          return true;
        }
      }

      if (utils.isIOS() && utils.isInStandalone()) {
        console.log('iOs en standalone.');
      } else {
        printPrivacityForm(idioma);
      }
      return false;
    }

    /**
     * Comprobar si la privacidad está aceptada
     * @param  {boolean} aceptado Acepta o no las cookies
     * @return {undefined}
     */
    function sendForm(aceptado) {
      var body = document.getElementsByTagName('body')[0];
      body.style.cursor = 'progress';
      btnAceptar.disabled = true;
      btnRechazar.disabled = true;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', getUrl() + '?accion=aceptar&chkConf=true&chkTerc=' + aceptado + '&chkPubli=' + aceptado, true);
      xhr.withCredentials = true;
      xhr.onload = function () {
        body.style.cursor = 'auto';
        btnAceptar.disabled = false;
        btnRechazar.disabled = false;
        var resp = JSON.parse(xhr.responseText);
        console.log(resp);
        if (resp.result) {
          if (resp.result === 'OK') {
            avisoCookies.remove();
            if (window.innerWidth < 768) {
              document.documentElement.classList.remove('scroll-disabled');
            }
          }
        }
      };
      xhr.onerror = function (e) {
        body.style.cursor = 'default';
        btnAceptar.disabled = false;
        btnRechazar.disabled = false;
        vg.alert.error(cookiesUpdateError);
      };
      xhr.send();
    }

    /**
     * Comprobar si la privacidad está aceptada
     * @return {undefined}
     */
    function sendFormJsp() {
      var body = document.getElementsByTagName('body')[0];
      body.style.cursor = 'progress';
      btnOk.disabled = true;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', getUrl() + '?accion=aceptar&chkConf=true&chkTerc=' + idChkTerc.checked + '&chkPubli=' + idChkPubli.checked, true);
      xhr.withCredentials = true;
      xhr.onload = function () {
        body.style.cursor = 'auto';
        btnOk.disabled = false;
        var resp = JSON.parse(xhr.responseText);
        console.log(resp);
        if (resp.result) {
          if (resp.result === 'OK') {
            if (resp.cookies) {
              for (var i = 0; i < resp.cookies.length; i++) {
                var cookie = resp.cookies[i];
                docCookies.setItem(cookie.name, cookie.value,
                  Number(cookie.maxAge), '/', cookie.domain);
              }
            }
            vg.alert.info(cookiesUpdateOk);

            // Redireccion interna
            setTimeout(() => {
              if (document.referrer.includes('vitoria-gasteiz.org')) {
                window.location=document.referrer;
              }
            }, 1000);
          } else {
            vg.alert.error(cookiesUpdateError);
          }
        }
      };
      xhr.onerror = function (e) {
        body.style.cursor = 'default';
        btnOk.disabled = false;
        vg.alert.error(cookiesUpdateError);
      };
      xhr.send();
    }

    /**
     * Muestra el formulario de aceptación de cookies
     * @param  {string} idioma Idioma de navegación
     * @return {undefined}
     */
    function printPrivacityForm(idioma) {
      // Se comprueba en el servlet si hay que solicitar la privacidad
      // Sólo se trata si no se había pintado ya previamente el formulario
      if (!mostrado) {
        mostrado = true;
        var xhr = new XMLHttpRequest();
        xhr.open('GET', getUrl() + '?idioma=' + idioma, true);
        xhr.withCredentials = true;
        xhr.onload = function () {
          var resp = JSON.parse(xhr.responseText);
          console.log(resp);
          if (window.location.href.indexOf('uid=u65df3783_18d8c5a91a7__34fb') != -1 ||
            window.location.href.indexOf('uid=u60c04389_144007bfb26__7fc5') != -1) {
            return;
          }
          if (resp.result && resp.privacidad) {
            if (resp.result === 'OK' &&
            resp.privacidad === 'solicitar' && resp.html) {
              avisoCookies = document.createElement('div');
              avisoCookies.setAttribute('id', 'avisoCookies');
              avisoCookies.innerHTML = resp.html;
              avisoCookies = document.body.appendChild(avisoCookies);

              if (window.innerWidth < 768) {
                document.documentElement.classList.add('scroll-disabled');
              }

              btnAceptar = avisoCookies.querySelector('#aceptarCookies');
              btnRechazar = avisoCookies.querySelector('#rechazarCookies');

              frmAvisoCookies = avisoCookies.querySelector('#frmAvisoCookies');
              frmAvisoCookies.setAttribute('action', getUrl());
              frmAvisoCookies.onsubmit = function (event) {
                sendForm(event.submitter.value === 'aceptar');
                return false;
              };
            }
          }
        };
        xhr.onerror = function (e) {
          console.error(e);
          // vg.alert.error(e);
        };
        xhr.send();
      }
    }

    /**
     * Precarga el formulario del jsp de la política de cookies
     * @return {undefined}
     */
    function fillPrivacityForm() {
      // Se comprueba en el servlet si hay que solicitar la privacidad
      // Sólo se trata si no se había pintado ya previamente el formulario

      frmAvisoCookies = document.getElementById('frmAvisoCookies');
      frmAvisoCookies.setAttribute('action', getUrl());
      idDivPubli = frmAvisoCookies.querySelector('#idDivPubli');
      idChkPubli = frmAvisoCookies.querySelector('#idChkPubli');
      idDivTerc = frmAvisoCookies.querySelector('#idDivTerc');
      idChkTerc = frmAvisoCookies.querySelector('#idChkTerc');
      btnOk = frmAvisoCookies.querySelector('#btnOk');

      idChkPubli.addEventListener('change', cambiarPrivacidadConf);

      if (docCookies.hasItem(cookieNameConf)) {
        if (docCookies.getItem(cookieNameConf) === 'aceptada') {
          idDivTerc.classList.remove('is-disabled');
          idChkTerc.removeAttribute('disabled');
          idChkTerc.removeAttribute('aria-disabled');

          idDivPubli.classList.remove('is-disabled');
          idChkPubli.removeAttribute('disabled');
          idChkPubli.removeAttribute('aria-disabled');

          if (docCookies.hasItem(cookieNameTerc)) {
            if (docCookies.getItem(cookieNameTerc) === 'aceptada') {
              idChkTerc.checked = true;
            }
          }

          if (docCookies.hasItem(cookieNamePubli)) {
            if (docCookies.getItem(cookieNamePubli) === 'aceptada') {
              idDivTerc.classList.add('is-disabled');
              idChkTerc.setAttribute('disabled', 'disabled');
              idChkTerc.setAttribute('aria-disabled', 'true');

              idChkPubli.checked = true;
            }
          }
        }
      }

      frmAvisoCookies.onsubmit = function () {
        sendFormJsp();
        return false;
      };
    }

    /**
     * Obtener la url en función del host
     * @return {undefined}
     */
    function getUrl() {
      var servidor = window.location.hostname;
      var url = urlProd;
      if (servidor.lastIndexOf('localhost.', 0) === 0 ||
      servidor.lastIndexOf('localsede.', 0) === 0) {
        url = urlLocal;
      } else
      if (servidor.lastIndexOf('dwww.', 0) === 0 ||
      servidor.lastIndexOf('dsedeelectronica.', 0) === 0 ||
      servidor.lastIndexOf('dblogs.', 0) === 0) {
        url = urlDesa;
      } else
      if (servidor.lastIndexOf('pwww.', 0) === 0 ||
        servidor.lastIndexOf('psedeelectronica.', 0) === 0) {
        url = urlPre;
      }
      return url;
    }

    /**
     * Cambia el estado del check de privacidad de terceros
     * en función de su valor
     * @return {undefined}
     */
    function cambiarPrivacidadConf() {
      if (this.checked) {
        idChkTerc.checked = true;
        idDivTerc.classList.add('is-disabled');
        idChkTerc.setAttribute('disabled', 'disabled');
        idChkTerc.setAttribute('aria-disabled', 'true');
      } else {
        idDivTerc.classList.remove('is-disabled');
        idChkTerc.removeAttribute('disabled');
        idChkTerc.removeAttribute('aria-disabled');
      }
    }

    /**
     * Cambia el mensaje de cambio de cookies correcto
     * @param {string} msg nuevo mensaje
     * @return {undefined}
     */
    function setCookiesUpdateOkMsg(msg) {
      cookiesUpdateOk = msg;
    }

    /**
     * Cambia el mensaje de cambio de cookies erróneo
     * @param {string} msg nuevo mensaje
     * @return {undefined}
     */
    function setCookiesUpdateErrorMsg(msg) {
      cookiesUpdateError = msg;
    }

    return {
      isConfigurationPrivacyAccepted: isConfigurationPrivacyAccepted,
      isThirdPartyPrivacyAccepted: isThirdPartyPrivacyAccepted,
      isPublicitaryPrivacyAccepted: isPublicitaryPrivacyAccepted,
      fillPrivacityForm: fillPrivacityForm,
      setCookiesUpdateOkMsg: setCookiesUpdateOkMsg,
      setCookiesUpdateErrorMsg: setCookiesUpdateErrorMsg,
    };
  }
);
