(function moduloHabilitarMenuContextualEnMovil(vg) {
  /**
   * Devuelve la función a asociar con diferentes elementos del menu
   * y que oculta o abre el menuContextual.
   * @param  {Element} menu   Objeto jQuery .extra-content
   * @return {Function}     Funcion listener que abre o cierra el menu
   */
  function accionAbrirCerrarMenuContextual(menu) {
    return function (evt) {
      menu.classList.toggle('extra-content--opened');
      document.documentElement.classList.toggle('scroll-disabled');
      evt.stopPropagation();
    };
  }

  /**
   * Devuelve el botón mostrar u ocultar que acompaña al menu contextual
   * en móviles.
   * @param  {Element} menu   Element .extra-content
   * @return {Element}        Element del botón generado
   */
  function generarBotonShowHide(menu) {
    const idioma = window.vg.utils.lang();
    var polyglot = new window.Polyglot();
    polyglot.extend({
      extra: {
        es: {
          menu: 'Más',
          resultados: 'Filtrar resultados',
        },
        eu: {
          menu: 'Gehiago',
          resultados: 'Iragazi emaitzak',
        },
        en: {
          menu: 'Menu',
          resultados: 'Filter results',
        },
        fr: {
          menu: 'Menu',
          resultados: 'Filtrer les résultats',
        },
      },
    });

    var element = document.createElement('button');
    var resultElement = document.querySelector('.result');
    element.classList.add('extra-content__toogle-btn');
    element.setAttribute('data-text', resultElement ? polyglot.t(`extra.${idioma}.resultados`) : polyglot.t(`extra.${idioma}.menu`));
    element.innerHTML = resultElement ? polyglot.t(`extra.${idioma}.resultados`) : polyglot.t(`extra.${idioma}.menu`);
    if (!resultElement) {
      element.classList.add('menu');
    }
    element.addEventListener('click', accionAbrirCerrarMenuContextual(menu));

    return element;
  }

  /**
   * Devuelve la capa que oscurece el resto de la web cuando se visualiza
   * el menu contextual en móviles.
   * @param  {Element} menu   Element .extra-content
   * @return {Element}        Element de la capa generada
   */
  function generarCapaOscurecer(menu) {
    var element = document.createElement('div');
    element.classList.add('extra-content-close');
    element.addEventListener('click', accionAbrirCerrarMenuContextual(menu));
    return element;
  }

  /**
   * Añade un evento swiped al documento para desplegar el menú
   * @param  {Element} boton    Element del botón generado
   * @param  {Element} menu     Element del menu generado
   */
  function generarEventoSwiped(boton, menu) {
    document.addEventListener('swiped-down', function (e) {
      e.stopPropagation();
      if (menu.classList.contains('extra-content--opened')) {
        boton.click();
      }
    });
    menu.addEventListener('swiped-up', function (e) {
      e.stopPropagation();
      if (!menu.classList.contains('extra-content--opened')) {
        boton.click();
      }
    });
  }

  /**
   * Envuelve el contenido del menu contextual en una nueva capa para permitir
   * realizar scroll vertical cuando la altura del aquél sea mayor que la
   * pantalla del dispositivo.
   * @param  {Element} menu   Elemento .extra-content
   * @return {undefined}
   */
  function wrapContenidoDelMenuParaHacerScroll(menu) {
    menu.innerHTML = ['<div class="extra-content__content">',
      menu.innerHTML,
      '</div>'].join('');
  }

  /**
   * Unir todos los aside .extra-content en un único menu
   * @param  {Element} menu Menu contextual principal
   * @return {undefined}
   */
  function unirMenusConsecutivos(menu) {
    if (!menu) return;
    menu.id = 'a11y-aside';
    var menus = menu.parentNode.children;
    if (menus.length > 1) {
      for (var i = 0, max = menus.length; i < max; i++) {
        var m = menus[i];
        if (m.tagName &&
          m.tagName.toLowerCase() === 'aside' &&
          m.isEqualNode &&
          !m.isEqualNode(menu)) {
          menu.innerHTML += m.innerHTML;
          m.parentNode.removeChild(m);
        }
      }
    }
  }

  /**
   * Eliminar el menú móvil antiguo que duplicaba por completo la información
   * del menu contextual, ya que el diseño obligaba a tener dos HTML iguales
   * que se mostraban exclusivamente en movil/tablet u ordenador
   * respectivamente.
   * @return {undefined}
   */
  function limpiarHtmlBasuraAntiguo() {
    var menuMovilViejo = document.querySelector('.aside-tabletMobile');
    if (menuMovilViejo) {
      var parent = menuMovilViejo.parentNode;
      // Borrar img mas/menos de moviles
      parent.removeChild(menuMovilViejo.previousElementSibling);
      // Borrar menu de móviles
      parent.removeChild(menuMovilViejo);
    }
  }

  /**
   * Tareas necesarias para que el menu contextual esté preparado para móviles
   * @param  {Element} menu   Elemento .extra-content
   * @return {undefined}
   */
  function init(menu) {
    if (!menu) return;
    wrapContenidoDelMenuParaHacerScroll(menu);
    var boton = generarBotonShowHide(menu);
    menu.insertBefore(boton, menu.firstChild);
    generarEventoSwiped(boton, menu);
    menu.parentNode.insertBefore(generarCapaOscurecer(menu), menu.nextSibling);
  }

  /**
   * Añade el icono y compara el titulo del contenido con el del menu contextual
   * para cambiar las clases
   * @param  {Element} menu
   * @return {undefined}
   */
  function initMenuContextual(menu) {
    if (!menu || !menu.firstChild) return;

    var url = window.location.href;
    var enlace = menu.firstChild.href;

    var urlId = url.split('?').length > 0 ? url.split('?')[0] : null;
    var urlParams = urlId && url.split('?')[1] ? url.split('?')[1].split('&') : null;
    var enlaceId = enlace && enlace.split('?').length > 0 ? enlace.split('?')[0] : null;
    var enlaceParams = enlaceId && enlace.split('?')[1] ? enlace.split('?')[1].split('&') : null;

    if (!urlId || !enlaceId || !urlParams || !enlaceParams) return;
    var iguales = comparar2Arrays(urlParams, enlaceParams);
    if ( urlId !== enlaceId || !iguales) return;

    menu.innerHTML = '<span class="nombre_tema">' + menu.firstChild.textContent + '</span>';
  }
  // eslint-disable-next-line require-jsdoc
  function comparar2Arrays(a, b) {
    if (a.length !== b.length) return false;
    const uniqueValues = new Set([...a, ...b]);
    for (const v of uniqueValues) {
      const aCount = a.filter((e) => e === v).length;
      const bCount = b.filter((e) => e === v).length;
      if (aCount !== bCount) return false;
    }
    return true;
  }

  /**
   * Habilitar el menú contextual para móviles basandonos en el código HTML
   * de Sopra y limpiar el HTML no necesario.
   * @return {undefined}
   */
  function habilitarMenuContextualEnMovil() {
    limpiarHtmlBasuraAntiguo();
    unirMenusConsecutivos(document.querySelector('.extra-content'));
    init(document.querySelector('.extra-content'));
    initMenuContextual(document.querySelector('.texto.primero'));
  }

  vg['menuContextualOnReady'] = habilitarMenuContextualEnMovil;

  vg.ready(habilitarMenuContextualEnMovil);
})(window.vg);
