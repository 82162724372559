window.vg.exports(
  // Nombre del módulo
  'readSpeaker',

  // Factoria del módulo
  function moduleFactory() {
    const idioma = window.vg.utils.lang();
    const polyglot = new window.Polyglot();
    polyglot.extend({
      read: {
        es: {
          listen: 'Escuchar la página',
          listening: 'Escuchando la página',
        },
        eu: {
          listen: 'Orria entzun',
          listening: 'Orria entzuten',
        },
        en: {
          listen: 'Listen to page',
          listening: 'Listening to the page',
        },
        fr: {
          listen: 'Écouter la page',
          listening: 'Écoute de la page',
        },
      },
    });

    /**
     * Inicializa el reproductor de readspeaker
     * @param {Element} play Botón de escuchar página
     * @return {Object} Objeto
     */
    function readerfactory(play) {
      const CUSTOMER_ID = 5339;
      const button = play;
      const playerId = 'player-reader';

      /**
       * Carga dinámica de las librerías de ReadSpeaker
       */
      const loadReader = () => {
        button.removeEventListener('click', loadReader);

        var script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `//cdn-eu.readspeaker.com/script/${CUSTOMER_ID}/webReader/webReader.js`;
        script.id = 'rs_req_Init';
        document.head.appendChild(script);

        script.addEventListener('load', () => {
          window.readpage(button.href, playerId);
        });
      };

      const read = () => {
        console.log('ReadSpeaker reading...');
        window.ReadSpeaker.ui.showPlayer(button.href, playerId);
      };

      (function init() {
        if (!button) return;
        button.addEventListener('click', loadReader);

        window.rsConf = {
          cb: {
            ui: {
              open: function () {
                button.classList.add('pointer-none');
                button.querySelector('.btn-header-utils__text').textContent = polyglot.t(`read.${idioma}.listening`);
                button.removeEventListener('click', read);
              },
              close: function () {
                button.classList.remove('pointer-none');
                button.querySelector('.btn-header-utils__text').textContent = polyglot.t(`read.${idioma}.listen`);
                button.addEventListener('click', read);
              },
            },
          },
        };
      })();

      return {
      };
    }

    return {
      init: function () {
        const button = document.querySelector('.play-reader');
        if (button) {
          const main = document.querySelector('#a11y-main');
          if (main) {
            readerfactory(button);
          } else {
            button.classList.add('visibility-hidden');
          }
        }
      },
    };
  }
);

