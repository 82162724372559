(function maquetacionLegendsSinPerderAccesibilidad(vg) {
  /**
   * Generar un elemento presentacional para maquetar correctamente el legend
   * de un fieldset del menu contextual. La causa es que los navegadores
   * renderizan de una manera especial las etiquetas 'legend' y 'fieldset'
   * y no hace caso a algunos estilos declarados en el partial
   * _menuContextual.css
   * @param  {Element} legend Instancia jQuery de una etiqueta legend
   * @return {undefined}
   */
  function generarElementoSoloParaInterfaz(legend) {
    var elementoSoloInterfaz = document.createElement('div');
    elementoSoloInterfaz.setAttribute('aria-hidden', 'true');
    elementoSoloInterfaz.setAttribute('role', 'presentation');
    elementoSoloInterfaz.innerHTML = legend.innerHTML;

    legend.parentNode.insertBefore(elementoSoloInterfaz, legend);
  }
  /**
   * Reservar la etiqueta legend para mantener semantica y a11y en lectores
   * de pantalla.
   * @param  {Element} legend Elemento legend
   * @return {undefined}
   */
  function reservarLegendParaLectores(legend) {
    legend.classList.remove('titulo');
    legend.classList.add('visually-hidden');
  }

  vg.ready(function () {
    var legend = document.querySelectorAll('.menuContextual legend');
    for (var i = 0, max = legend.length; i < max; i++) {
      generarElementoSoloParaInterfaz(legend[i]);
      reservarLegendParaLectores(legend[i]);
    }
  });
})(window.vg);
