window.vg.exports(

  // Nombre del módulo
  'buttons',

  // Factoria del módulo
  function () {
    /**
     * Prototipo de los botones toggle: aquellos que muestran y ocultan una
     * capa asociada.
     *
     * @typedef {Object} ToggleButton
     * @property {Element} node boton en el DOM
     * @property {Element} controls capa asociada al boton
     * @property {Function} init función de inicialización
     * @property {Function} getState devuelve true si el botón está expandido
     * y su capa asociada visible; false, en caso contrario
     * @property {Function} setState establece el estado y actúa en
     * consecuencia
     * @property {Function} toggleState cambia el estado por el opuesto
     */
    var toggleButtonProto = {
      node: null,
      controls: null,
      init: function (buttonNode) {
        this.node = buttonNode;
        var controlledNode = this.node.getAttribute('aria-controls')
          ? document.getElementById(this.node.getAttribute('aria-controls'))
          : null;

        if (controlledNode === null) {
          console.warn('No se encuentra la capa que controla el boton');
          return;
        }

        this.controls = controlledNode;
        this.controls.style.display = '';

        var initialState = this.getState();
        this.setState(initialState);

        var self = this;
        this.node.addEventListener('click', function (e) {
          e.preventDefault();
          self.toggleState();
        });
      },
      /**
       * Devuelve true si el botón está expandido y su capa asociada visible;
       * false, en caso contrario.
       *
       * @return {Boolean} true si está expandido
       */
      getState: function getToggleButtonState() {
        return this.node.getAttribute('aria-expanded') === 'true';
      },
      /**
       * Establece el estado y actúa en consecuencia:
       * - Si se envía "true", se muestra la capa asociada y se marca el estado
       *   a expandido.
       * - Con "false", se oculta.
       *
       * @param {Boolean} state true, si se quiere expandir; false, en otro caso
       * @return {undefined}
       */
      setState: function setToggleButtonState(state) {
        this.node.setAttribute('aria-expanded', state);
        this.controls.hidden = !state;
        this.controls.setAttribute('aria-hidden', !state);
      },
      /**
       * Cambia el estado por el opuesto al actual
       *
       * @return {undefined}
       */
      toggleState: function toggleState() {
        this.setState(!this.getState());
      },
    };

    /**
     * Prototipo de los botones toggle visualizados como iconos "+" y "-".
     *
     * @typedef {Object} ToggleIconButton
     */
    var toggleIconButtonProto = Object.assign(
      Object.create(toggleButtonProto),
      {
        init: function (btn) {
          var hasPreviousShowHideEventListener =
            btn.getAttribute('data-initialized') === 'true';
          if (hasPreviousShowHideEventListener) return;

          // Deprecated (use aria-expanded)
          if (btn.classList.contains('is-expanded') &&
            !btn.hasAttribute('aria-expanded')) {
            btn.setAttribute('aria-expanded', true);
            btn.classList.remove('is-expanded');
          }

          if (btn.getAttribute('aria-expanded') != true) {
            if (vg.utils.isMobileDevice() || window.innerWidth < 768) {
              btn.setAttribute('aria-expanded', false);
            }
          }

          btn.classList.add('btn-toggle', 'btn');

          toggleButtonProto.init.call(this, btn);
          btn.setAttribute('data-initialized', true);
        },
      }
    );

    var toogleTransitionButtonProto = Object.assign(
      Object.create(toggleButtonProto),
      {
        setState: function setToggleButtonState(state) {
          this.node.setAttribute('aria-expanded', state);
          // this.controls.hidden = !state;
          this.controls.setAttribute('aria-hidden', !state);
          (state) ? this.controls.classList.add('is-active') : this.controls.classList.remove('is-active');
        },
      }
    );

    var toogleMainButtonProto = Object.assign(
      Object.create(toggleButtonProto),
      {
        init: function (buttonNode) {
          this.node = buttonNode;

          var controlledNode = this.node.getAttribute('aria-controls')
            ? document.getElementById(this.node.getAttribute('aria-controls'))
            : null;

          if (controlledNode === null) {
            this.controls = buttonNode.parentNode.nextElementSibling;
            this.controls.id = this.controls.id ? this.controls.id : vg.utils.uid();
            this.node.setAttribute('aria-controls', this.controls.id);
          } else {
            this.controls = controlledNode;
          }
          this.controls.style.display = '';

          if (!(this.node instanceof HTMLButtonElement) && this.node.getAttribute('role') !== 'button') {
            this.node.setAttribute('role', 'button');
          }

          var initialState = this.getState();
          this.setState(initialState);

          var self = this;
          this.node.addEventListener('click', function (e) {
            e.preventDefault();
            self.toggleState();
          });
        },
        getState: function getToggleButtonState() {
          if (this.node.getAttribute('aria-expanded') === null) {
            return true;
          } else {
            return this.node.getAttribute('aria-expanded') === 'true';
          }
        },
      }
    );

    var toogleSearchFiltersButtonProto = Object.assign(
      Object.create(toggleButtonProto),
      {
        init: function (buttonNode) {
          this.node = buttonNode;

          var controlledNode = this.node.getAttribute('aria-controls')
            ? document.getElementById(this.node.getAttribute('aria-controls'))
            : null;

          if (controlledNode === null) {
            this.controls = buttonNode.nextElementSibling;
            this.controls.id = this.controls.id ? this.controls.id : vg.utils.uid();
            this.node.setAttribute('aria-controls', this.controls.id);
          } else {
            this.controls = controlledNode;
          }
          this.controls.style.display = '';

          if (!(this.node instanceof HTMLButtonElement) && this.node.getAttribute('role') !== 'button') {
            this.node.setAttribute('role', 'button');
          }

          var initialState = this.getState();
          this.setState(initialState);

          var self = this;
          this.node.addEventListener('click', function (e) {
            e.preventDefault();
            self.toggleState();
          });
        },
        getState: function getToggleButtonState() {
          if (this.node.getAttribute('aria-expanded') === null) {
            return true;
          } else {
            return this.node.getAttribute('aria-expanded') === 'true';
          }
        },
      }
    );
    /**
     * Instancia una nueva instancia de ToggleButton para el boton pasado por
     * parámetro.
     *
     * @param {Element} btn Elemento button al que aplicar la funcionalidad
     * @return {ToggleButton} instancia generada
     */
    function toggleButtonFactory(btn) {
      var instance = Object.create(toggleButtonProto);
      instance.init(btn);
      return instance;
    }

    /**
     * Instancia una nueva instancia de ToggleIconButton para el boton pasado
     * por parámetro. El botón se visualizará como un icono "+" o "-".
     *
     * @param {Element} btn Elemento button al que aplicar la funcionalidad
     * @return {ToggleIconButton} instancia generada
     */
    function toggleIconButtonFactory(btn) {
      var instance = Object.create(toggleIconButtonProto);
      instance.init(btn);
      return instance;
    }

    /**
     * Instancia una nueva instancia de ToggleIconButton para el boton pasado
     * por parámetro. El botón se visualizará como un icono "+" o "-".
     *
     * @param {Element} btn Elemento button al que aplicar la funcionalidad
     * @return {ToggleIconButton} instancia generada
     */
    function toogleTransitionButtonFactory(btn) {
      var instance = Object.create(toogleTransitionButtonProto);
      instance.init(btn);
      return instance;
    }

    /**
     * Instancia una nueva instancia de ToggleIconButton para el boton pasado
     * por parámetro. El botón se visualizará como un icono "+" o "-".
     *
     * @param {Element} btn Elemento button al que aplicar la funcionalidad
     * @return {ToggleIconButton} instancia generada
     */
    function toogleMainButtonFactory(btn) {
      var instance = Object.create(toogleMainButtonProto);
      instance.init(btn);
      return instance;
    }

    /**
     * Instancia una nueva instancia de ToggleIconButton para el boton pasado
     * por parámetro. El botón se visualizará como un icono "+" o "-".
     *
     * @param {Element} btn Elemento button al que aplicar la funcionalidad
     * @return {ToggleIconButton} instancia generada
     */
    function toogleSearchFiltersButtonFactory(btn) {
      var instance = Object.create(toogleSearchFiltersButtonProto);
      instance.init(btn);
      return instance;
    }

    /**
     * Añade funcionalidades propias de un toogletip a una instancia de
     * ToggleButton:
     *
     * - Ocultar la capa asociada al pinchar fuera de la misma.
     * - Ocultar la capa asociada al pulsar la tecla ESC
     *
     * Mas info sobre toggletips en:
     * https://inclusive-components.design/tooltips-toggletips/
     *
     * @param {ToggleButton} toggleBtn instancia a la que añadir funcionalidad
     */
    function addToggletipEventListeners(toggleBtn) {
      var self = toggleBtn;
      document.addEventListener('click', function (e) {
        if (self.controls !== e.target &&
          !self.controls.contains(e.target) &&
          self.node !== e.target) {
          self.setState(false);
        }
      });

      // ESC
      toggleBtn.controls.addEventListener('keydown', function (e) {
        if ((e.keyCode || e.which) === 27) self.setState(false);
      });
    }

    /**
     * Devuelve un booleano indicando si hay que ignorar un boton y no añadirle
     * la funcionalidad automáticamente
     *
     * @param {Element} elem  Boton a testear
     * @return {boolean}  true si se debe ignorar; false, en caso contrario.
     */
    function isIgnored(elem) {
      if (elem.parentElement &&
      elem.parentElement.id &&
      /j16menuli\d/.test(elem.parentElement.id)) {
        // Ignorar si es un boton del menú de j16-02w
        return true;
      }
      return false;
    }

    return {
      init: function () {
        var i;
        var max;
        // Prepare tree nodes to be initialized as toggle icon buttons
        var treeToggles = document.querySelectorAll('.tree-node__btn-toggle');
        for (i = 0, max = treeToggles.length; i < max; i++) {
          var btn = treeToggles[i];
          var treeBranch = btn.nextSibling;
          do {
            if (treeBranch.classList &&
            treeBranch.classList.contains('tree-node__children')) {
              break;
            }
          } while ((treeBranch = treeBranch.nextSibling));
          if (treeBranch && treeBranch !== null) {
            treeBranch.id = treeBranch.id || window.vg.utils.uid();
            btn.setAttribute('aria-controls', treeBranch.id);
          }
        }
        // Initialize toggle icon buttons
        var targets = document.querySelectorAll('.btn-toggle');
        for (i = 0, max = targets.length; i < max; i++) {
          if (!isIgnored(targets[i])) toggleIconButtonFactory(targets[i]);
        }
        // Initialize toggle icon buttons
        var targetsFilters = document.querySelectorAll('.btn-toggle-filters');
        for (i = 0, max = targetsFilters.length; i < max; i++) {
          if (!isIgnored(targetsFilters[i])) {
            addToggletipEventListeners(toggleButtonFactory(targetsFilters[i]));
          }
        }
        // Initialize icon buttons
        var targetsAfters = document.querySelectorAll('.main__grid-box > header > h2', '::after');
        for (i = 0, max = targetsAfters.length; i < max; i++) {
          if (!isIgnored(targetsAfters[i])) {
            toogleMainButtonFactory(targetsAfters[i]);
          }
        }
        // mailbox buttons legend
        var targetsLegendsAfters = document.querySelectorAll('.container-hide-legend > h2', '::after');
        for (i = 0, max = targetsLegendsAfters.length; i < max; i++) {
          if (!isIgnored(targetsLegendsAfters[i])) {
            toogleMainButtonFactory(targetsLegendsAfters[i]);
          }
        }
        // Boton conexión de cabecera (usuario CAS conectado)
        var btnConexion = document.querySelector('.conexion__btn.is-auth');
        if (btnConexion !== null) {
          addToggletipEventListeners(toggleButtonFactory(btnConexion));
        }

        // Boton language de cabecera
        var btnLang = document.querySelector('.header-utils__lang-dropdown-toggle');
        if (btnLang !== null) {
          addToggletipEventListeners(toggleButtonFactory(btnLang));
        }

        // Boton search de cabecera
        var btnSearch = document.querySelector('.header-main__search-dropdown-toggle');
        if (btnSearch !== null) {
          addToggletipEventListeners(toogleTransitionButtonFactory(btnSearch));

          btnSearch.addEventListener('click', function (e) {
            document.getElementById('termino').focus();
          });
        }

        // Boton idioma de nav
        var btnNavLang = document.querySelector('.main-menu__item--lang > dropdown-toggle');
        if (btnNavLang !== null) {
          addToggletipEventListeners(toggleButtonFactory(btnNavLang));
        }
      },
      /**
       * Instancia una nueva instancia de ToggleIconButton para el boton pasado
       * por parámetro. El botón se visualizará como un icono "+" o "-".
       *
       * @param {Element} btn Elemento button al que aplicar la funcionalidad
       * @return {ToggleIconButton} instancia generada
       */
      toggleIconButtonFactory: toggleIconButtonFactory,
      /**
       * Instancia una nueva instancia de ToggleButton para el boton pasado por
       * parámetro.
       *
       * @param {Element} btn Elemento button al que aplicar la funcionalidad
       * @return {ToggleButton} instancia generada
       */
      toggleButtonFactory: toggleButtonFactory,
      /**
       * Instancia una nueva instancia de ToggleButton con transicciones css
       * para el boton pasado por parámetro.
       *
       * @param {Element} btn Elemento button al que aplicar la funcionalidad
       * @return {ToggleButton} instancia generada
       */
      toogleTransitionButtonFactory: toogleTransitionButtonFactory,
      /**
       * Instancia las funcionalidades propias de un toogletip para el boton
       * pasado por parámetro.
       *
       * @param {ToggleButton} boton toggle
       */
      addToggletipEventListeners: addToggletipEventListeners,
      /**
       * Instancia las funcionalidades propias de un toogletip para los filtros
       * de los buscadores
       *
       * @param {ToggleButton} boton toggle
       */
      toogleSearchFiltersButtonFactory: toogleSearchFiltersButtonFactory,
    };
  }

);
