window.vg.exports(

  // Nombre del módulo
  'tablist',

  // Factoria del módulo
  function moduleFactory() {
    var utils = window.vg.utils;

    var observerPattern = {
      /**
       * Mixin para aplicar el rol de sujeto (observable) del patron Observer a
       * un objeto.
       *
       * @param {Object} obj  Objeto al que aplicar el patron
       * @return {Object}     Objeto con el rol aplicado
       */
      observableMixin: function observableMixin(obj) {
        return Object.assign(obj, {
          observers: [],
          registerObserver: function (observer) {
            this.observers.push(observer);
          },
          notifyObservers: function (observable) {
            this.observers.forEach(function (observer) {
              observer.update(observable);
            });
          },
        });
      },
      /**
       * Mixin para aplicar el rol de observador del patron Observer a un
       * objeto.
       *
       * @param {Object} obj Objeto al que aplicar el patron
       * @param {Function} fnUpdate función update al ser notificado
       * @return {Object} Objeto con el rol aplicado
       */
      observerMixin: function observerMixin(obj, fnUpdate) {
        return Object.assign(obj, {
          update: fnUpdate,
        });
      },
    };

    /**
     * Devuelve un objeto que representa a una pestaña
     * @param  {Element} elem Enlace
     * @return {Object}      Objeto Tab
     */
    function tabFactory(elem) {
      var node = elem;
      var href = node.getAttribute('href');
      var selected = false;
      var tabPanelNode;
      var uid = utils.uid();
      var returnedObject;

      /**
       * Devuelve el elemento asociado a la pestaña
       * @return {Element} Contenido de la pestaña
       */
      function getTabPanelNode() {
        var isInteractive =
          href !== null &&
          typeof href !== 'undefined' &&
          href.startsWith('#');
        if (!isInteractive) {
          return;
        } else {
          return document.getElementById(href.substring(1));
        }
      }

      /**
       * Oculta el contenido asociado
       * @return {undefined}
       */
      function hideTabPanel() {
        tabPanelNode.setAttribute('aria-hidden', true);
        tabPanelNode.hidden = true;
      }

      /**
       * Muestra el contenido asociado
       * @return {undefined}
       */
      function showTabPanel() {
        tabPanelNode.setAttribute('aria-hidden', false);
        tabPanelNode.hidden = false;
      }

      returnedObject = {
        addKeyboardNavigation: function (prevTab, nextTab) {
          node.addEventListener('keydown', function tabKeyNavEvent(evt) {
            var KEY_ENTER = 13;
            var KEY_SPACE = 32;
            var KEY_LEFT = 37;
            var KEY_UP = 38;
            var KEY_RIGHT = 39;
            var KEY_DOWN = 40;

            var key = evt.which || evt.keyCode;
            switch (key) {
              case KEY_UP:
              case KEY_LEFT:
                prevTab.focus();
                evt.preventDefault();
                break;
              case KEY_DOWN:
              case KEY_RIGHT:
                nextTab.focus();
                evt.preventDefault();
                break;
              case KEY_ENTER:
              case KEY_SPACE:
                node.click();
                evt.preventDefault();
                break;
              default:
            }
          });
        },
        deselect: function () {
          if (selected) {
            node.setAttribute('aria-selected', false);
            node.setAttribute('tabindex', '-1');
            node.classList.remove('is-selected');
            node.parentNode.classList.remove('is-selected');
            hideTabPanel();
            selected = false;
            this.notifyObservers(this);
          }
        },
        element: node,
        focus: function () {
          node.focus();
        },
        isEqual: function (tab) {
          return uid === tab.uid();
        },
        isSelected: function () {
          return selected;
        },
        select: function () {
          if (!selected) {
            node.setAttribute('aria-selected', true);
            node.setAttribute('tabindex', '0');
            node.classList.add('is-selected');
            node.parentNode.classList.add('is-selected');
            showTabPanel();
            selected = true;
          }
          this.notifyObservers(this);
        },
        uid: function () {
          return uid;
        },
      };
      observerPattern.observableMixin(returnedObject);

      (function init() {
        selected = node.classList.contains('is-selected') ||
        node.getAttribute('aria-selected') === 'true';

        tabPanelNode = getTabPanelNode();
        if (!tabPanelNode) return;

        node.id = node.id || uid;
        node.setAttribute('role', 'tab');
        node.setAttribute('aria-controls', href.substring(1));
        tabPanelNode.setAttribute('role', 'tabpanel');
        tabPanelNode.setAttribute('aria-labelledby', node.id);

        if (selected) {
          node.setAttribute('aria-selected', true);
          node.setAttribute('tabindex', '0');
          node.classList.add('is-selected');
          node.parentNode.classList.add('is-selected');
          showTabPanel();
        } else {
          node.setAttribute('aria-selected', false);
          node.setAttribute('tabindex', '-1');
          hideTabPanel();
        }

        node.addEventListener('click', (function tabListener(evt) {
          evt.preventDefault();
          returnedObject.select();
          const scrollElement = returnedObject.element.parentNode;
          if (scrollElement.parentNode.scrollWidth > scrollElement.parentNode.clientWidth) {
            scrollElement.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'nearest'});
          }
        }));
      })();

      return returnedObject;
    }

    /**
     * Aplicar el patrón fachada a una pestaña para exponer fuera del módulo
     * sólo la funcionalidad necesaria.
     *
     * Por ejemplo: Objeto tab que se envía junto con un evento.
     *
     * @param {Object} tab  Objeto tab
     * @return {Object}     Objeto simplificado
     */
    function exposedTabFacade(tab) {
      return {
        element: tab.element,
        deselect: tab.deselect.bind(tab),
        // isEqual: tab.isEqual.bind(tab),
        isSelected: tab.isSelected.bind(tab),
        select: tab.select.bind(tab),
      };
    }

    /**
     * Instancia y genera la funcionalidad de un grupo de pestañas
     * @param  {Element} tablist Elemento DOM padre de las pestañas
     * @return {undefined}
     */
    function tablistFactory(tablist) {
      var node = tablist;
      var tabs;

      /**
       * Devuelve el indice de una tab dentro del array de tabs
       * @param  {Object} tab Objeto Tab
       * @return {Number}     index
       */
      function getTabIndex(tab) {
        for (var i = 0, max = tabs.length; i < max; i++) {
          if (tab.isEqual(tabs[i])) {
            return i;
          }
        }
        return -1;
      }

      /**
       * Devuelve las pestañas hermanas de una determinada
       * @param  {Object} tab Objeto Tab
       * @return {Array}      Pestañas hermanas
       */
      function getSiblings(tab) {
        var cloned = tabs.slice(0);
        // Eliminar la tab concreta
        cloned.splice(getTabIndex(tab), 1);
        return cloned;
      }

      var returnedObject = {
        element: node,
      };
      observerPattern.observerMixin(returnedObject, function (observableTab) {
        if (observableTab.isSelected()) {
          getSiblings(observableTab).forEach(function (sibling) {
            sibling.deselect();
          });

          var customTabSelectEvt = new CustomEvent('vg.tabselect', {
            detail: {
              tab: exposedTabFacade(observableTab),
              index: getTabIndex(observableTab),
            },
          });

          node.dispatchEvent(customTabSelectEvt);
        }
      });

      /**
       * Instancia las pestañas y añade el click a cada una de ellas
       * @return {undefined}
       */
      (function init() {
        var tablistSize = node.children.length;
        if (tablistSize === 0) {
          console.warn('No se puede inicializar un .tablist sin hijos.');
          return;
        }
        node.setAttribute('role', 'tablist');

        node.addEventListener('wheel', (evt) => {
          evt.preventDefault();
          node.scrollLeft += evt.deltaY;
        });

        // Instanciar las pestañas
        tabs = [];
        for (var i = 0; i < tablistSize; i++) {
          var li = node.children[i];
          li.setAttribute('role', 'presentation');
          var tab = tabFactory(li.firstElementChild || li);
          if (tab) {
            tab.registerObserver(returnedObject);
            tabs.push(tab);
          }
        }

        // Añadir la navegación por teclado
        tabs.forEach(function (tab, index) {
          var prevTab = (index > 0) ? tabs[index - 1] : tabs[tablistSize - 1];
          var nextTab = (index < tablistSize - 1) ? tabs[index + 1] : tabs[0];
          tab.addKeyboardNavigation(prevTab, nextTab);
        });

        var selected = node.querySelector('li.is-selected, .nav-tabs>li.active');
        if (selected === null) {
          tabs[0].select();
        } else {
          const diff = (selected.parentNode.clientWidth - selected.offsetWidth) / 2;
          selected.parentNode.scrollBy({behavior: 'smooth', left: selected.clientLeft - diff});
        }
      })();

      return returnedObject;
    }

    return {
      init: function () {
        var tabs = document.querySelectorAll('.tablist, .nav-tabs');
        for (var t = 0, tmax = tabs.length; t < tmax; t++) {
          tablistFactory(tabs[t]);
        }
      },
    };
  }

);
