window.vg.exports(

  // Nombre del módulo
  'inputDate',

  // Factoria del módulo
  function () {
    var $ = window.jQuery;
    var utils = window.vg.utils;

    /* Exponer una función para modernizar los campos con Calendar antiguo
     * (calendar.js) sin tener que cambiar los script de cada uno */
    window.Calendar = {
      _TT: {
        DEF_DATE_FORMAT: '',
      },
      setup: function (params) {
        var selector = (params.inputField.indexOf('#') === 0)
          ? params.inputField
          : '#' + params.inputField;

        var imagenParaEliminar = (params.button)
          ? document.getElementById(params.button)
          : undefined;

        if (imagenParaEliminar) {
          imagenParaEliminar.parentNode.removeChild(imagenParaEliminar);
        }

        window.vg.ready(function () {
          /* eslint-disable-next-line max-len */
          console.warn('Encontrado un datepicker deprecado (usaba calendar.js).');
          datepicker(selector);
        });
      },
    };

    /**
     * Función que devuelve un input exacto al suministrado pero
     * con el type de tipo text
     * @param  {Element} inputDate Input original
     * @return {Element}           Copia del input original con el type deseado
     */
    function downgradeInputDateToText(inputDate) {
      var lang = document.documentElement.getAttribute('lang') || 'es';
      var newInput;
      var val = inputDate.value;

      if (!val) {
        // Extraer el value directamente del HTML si el navegador ha detectado
        // un formato no ISO (yyyy-mm-dd)
        var match = /.* value=["']([^"']*)["'].*/.exec(inputDate.outerHTML);
        if (match && match.length > 1) {
          val = match[1];
        }
      }

      if (utils.isIE(8)) {
        var tmpParent = document.createElement('div');
        var newHtml = inputDate.outerHTML.replace(
          /type=[a-zA-Z]*/,
          'type=text'
        );
        tmpParent.innerHTML = newHtml;
        newInput = tmpParent.firstChild;
      } else {
        newInput = inputDate.cloneNode(false);
        newInput.type = 'text';
      }

      // Establecer el valor al nuevo input en formato web municipal (no ISO)
      if (val) {
        if (/\d{4}-\d{2}-\d{2}/gi.test(val)) {
          var dateFormat = (lang === 'eu')
            ? '$1/$2/$3' // EU : yyyy/mm/dd
            : '$3/$2/$1'; // ES & EN (UK) : dd/mm/yyyy
          val = val.replace(/(\d{4})-(\d{2})-(\d{2})/gi, dateFormat);
        }
        newInput.value = val;
      }

      return newInput;
    }

    /**
     * Aplicar jQueryUI Datepicker a elemento pasado por parametro y marca los
     * estilos del padre.
     * @param  {Element} inputElement Element input
     * @return {undefined}
     */
    function datepicker(inputElement) {
      if (!$ || !$.datepicker) return;
      $(inputElement)
        .datepicker({
          showOn: 'button',
          buttonImage: '/http/comun/j38-01/img/svg/sprite.svg#icon-calendar-view',
          buttonImageOnly: true,
          buttonText: 'Selecciona una fecha',
          changeMonth: true,
          changeYear: true,
          gotoCurrent: true,
          yearRange: '-100:+10',
        })
        .parent()
        .addClass('field--has-datepicker');
    }

    /**
     * Establece la configuracioón por defecto del plugin de jQuery UI
     * @param  {String} lang  idioma
     * @return {undefined}
     */
    function configureDefaultDatepicker(lang) {
      if (!$ || !$.datepicker) return;
      var dateFormat =
      ($.datepicker._defaults && $.datepicker._defaults.dateFormat)
        ? $.datepicker._defaults.dateFormat
        : false;

      $.datepicker.setDefaults($.datepicker.regional[lang]);
      $.datepicker.setDefaults({
        showOn: 'button',
        buttonImage: '/http/comun/j38-01/img/svg/sprite.svg#icon-calendar-view',
        buttonImageOnly: true,
        buttonText: 'Selecciona una fecha',
      });
      if (dateFormat) {
        $.datepicker.setDefaults({
          dateFormat: dateFormat,
        });
      }
    }


    /**
     * Comprueba si hay que excluir la url actual del cambio de date a text
     * @return {boolean}
     */
    function isExcludeUrl() {
      var url = document.location.href;
      var excludeUrls = ['/geovitoria/geo'];

      return excludeUrls.some( function (element) {
        return url.includes(element);
      });
    }

    return {
      addDatepicker: function applyPolyfill(options) {
        var lang = document.documentElement.getAttribute('lang') || 'es';
        var defaultOptions = {
          nonSupportingBrowsersOnly: true,
          downgradeTypeDateToText: true,
        };
        options = options = Object.assign({}, defaultOptions, options);

        var addDatepicker = !isExcludeUrl() &&
        (!options.nonSupportingBrowsersOnly ||
        (window.Modernizr.inputtypes && !window.Modernizr.inputtypes.date));

        if (addDatepicker) {
          configureDefaultDatepicker(lang);

          var inputArray = document.querySelectorAll('input[type="date"]');
          for (var i = 0, max = inputArray.length; i < max; i++) {
            var inputDate = inputArray[i];

            if (options.downgradeTypeDateToText) {
              var downgraded = downgradeInputDateToText(inputDate);
              inputDate.parentNode.replaceChild(downgraded, inputDate);
              inputDate = downgraded;
            }

            datepicker(inputDate);
          }
        }
      },
    };
  }

);
